import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Admin from "./components/Admin";
import MapSearch from "./components/mapsearchpage";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import PropertyDetails from "./components/propertyDetails";
import ProtectedRoute from "./utils/ProtectedRouter";
import { Events } from "./pages/events";
import Team from "./pages/team";
import { Layout } from "./components/layout";
import {Contact} from "./pages/contact"
import { AboutUs } from "./pages/aboutUs";
import { EricNur } from "./pages/EricNur";
import BlogList from "./pages/belog";
import BlogDetail from "./pages/BlogDetail";
import Community from "./pages/community";
import {FloorPlan} from "./pages/floor-plan";
import Bordmembers from "./pages/boardMembers";
import { AgentAdmin } from "./pages/agentAdmin";
import {Doc} from './pages/doc'
import { UserDashboard } from "./pages/UserDashboard";

function App() {
  return (
    <Router>
      <div className="font-Poppins bg-white">
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/ARN-Team" element={<Team />} />
            <Route path="/mapsearch" element={<MapSearch />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/eric-nur" element={<EricNur />} />
            <Route path="/community" element={<Community />} />
            <Route path="/Floor-Plans" element={<FloorPlan />} />
            <Route path="/Rules-And-Regulations" element={<Doc />} />
            <Route path="/board-members" element={<Bordmembers />} />
            <Route
              path="/Terms-Of-Use"
              element={<TermsAndConditions />}
            />
            <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
              <Route path="/blog" element={<BlogList />} />
            <Route path="/propertyDetails/:id" element={<PropertyDetails />} />
              <Route path="/blog/:id" element={<BlogDetail />} />
          </Route>

          {/* PROTECTED ROUTES */}
          <Route element={<ProtectedRoute allowedRoles={["super admin"]} />}>
            <Route path="/admin" element={<Admin />} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={["agent admin"]} />} >
          <Route path="/agent-admin" element={<AgentAdmin />} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={["public"]} />} >
          <Route path="/user-dashboard" element={<UserDashboard />} />
          </Route>

          {/* HANDLE 404 PAGE */}
          <Route
            path="*"
            element={
              <div className="grid h-screen place-content-center bg-white px-4">
                <h1 className="uppercase tracking-widest text-gray-500">
                  Not found
                </h1>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;