
import React, { useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

export const Modal =({visible, onClose, accepted, rejected})=> {

    const accept = () => {
        accepted();
    }

    const reject = () => {
        onClose();
        rejected();
    }

      const customFooter = () => {
        return (
          <div className="flex gap-5 justify-end items-end">
            <Button
              label="No"
              icon="pi pi-times"
              onClick={reject}
              className="btn focus:outline-none focus:ring-0 hover:bg-[#002f499e]"
            />
             
              <Button
                label="Yes"
                icon="pi pi-check"
                onClick={() => accept()}
                className="bg-red-500 text-white btn hover:bg-red-600 focus:outline-none focus:ring-0"
                autoFocus
              />
            
          </div>
        );
      };

    return (
        <>
            <ConfirmDialog group="declarative"  visible={visible} onHide={() => onClose()} message="Do you want to duplicate a property?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" footer={customFooter} />
        </>
    )
}
        