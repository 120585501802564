import React, { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { FaImages, FaRegEdit } from "react-icons/fa";
import { RiImageAddFill } from "react-icons/ri";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Input } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IoIosSearch } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";
import  FileUploader  from "../components/FileUpload/FileUpload";
import { useFloorPlan } from "../hooks/useFloorPlan";
export const FloorPlanManagement = () => {
  const {
    FloorPlan,
    isLoading,
    error,
    addFloorPlan,
    updateFloorPlan,
    deleteFloorPlan,
    createFloorPlanLoading,
    createFloorPlanSuccess,
    updateFloorPlanLoading,
    updateFloorPlanSuccess,
    deleteFloorPlanLoading,
  } = useFloorPlan();
  const [visible, setVisible] = useState(false);
  const [plan, setPlan] = useState({id:"", name:""});
    const [confirmVisible, setConfirmVisible] = useState(false);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [selectedFloorPlan, setSelectedFloorPlan] = useState({
    id: null,
    title: "",
    files: [],
  });
  const [visibleRight, setVisibleRight] = useState(false);

  const floorPlanFormik = useFormik({
    initialValues: {
      title: "",
      files: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (values) => {
      addFloorPlan(values);
    },
  });

  useEffect(()=>{
    if(createFloorPlanSuccess){
        setVisible(false);
        floorPlanFormik.resetForm();
    }
  },[createFloorPlanSuccess])

  const updateFloorPlanFormik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (values) => {
      updateFloorPlan({ hoaId: selectedFloorPlan.id, hoaData: values });
      updateFloorPlanLoading === false &&
        updateFloorPlanSuccess &&
        (() => {
          setVisibleRight(false);
          updateFloorPlanFormik.resetForm();
        })();
    },
  });


  const confirmDelete = (rowData) => {
    setPlan({id:rowData.id, name:rowData.title});
    setConfirmVisible(true);
  };
  const handleDelete = () => {
    deleteFloorPlan(plan.id);
    setConfirmVisible(false);
  };
  const handleReject = () => {
    setConfirmVisible(false);
    setPlan({id:"", name:""});
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-end justify-end">
        <Input
          placeholder="Search"
          className="text-sm focus:ring-0 focus:outline-none"
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          prefix={<IoIosSearch />}
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex gap-5">
          <MdOutlineDeleteOutline
            onClick={() => confirmDelete(rowData)}
            size={24}
            className="text-red-600 hover:text-red-500 cursor-pointer"
          />
          <FaRegEdit
            onClick={() => {
              setVisibleRight(true);
              setSelectedFloorPlan({
                id: rowData.id,
                title: rowData.title,
                files: rowData.files,
              });
            }}
            className="text-[#003049] hover:text-[#002f499e] cursor-pointer"
            size={24}
          />
        </div>
      </>
    );
  };

  const countBodyTemplate = (rowData) => {
    return FloorPlan.indexOf(rowData) + 1;
  };

  const header = renderHeader();
  const customHeader = () => (
    <div className="flex items-start justify-start">
      <h1 className="font-semibold">Update Floor Plan's information</h1>
    </div>
  );

  const discriptionBodyTemplate = (rowData) => {
    const maxLength = 30;
  
    return (
      <div className="flex space-x-2">
        {rowData.floorUrls.map((floor, index) => {
          const url = floor.url;
  
          // Check only the part of the URL before the "?" (query parameters)
          const fileUrl = url.split('?')[0]; // Get URL part before "?"
          
          return (
            <div key={index} className="flex items-center">
              {fileUrl.endsWith('.pdf') ? (
                // Display PDF link
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-sm text-[#003049] underline cursor-pointer">
                  {url.length > maxLength ? `${url.substring(0, maxLength)}...` : url}
                </a>
              ) : (
                <img 
                  src={url} 
                  alt="Floor Plan" 
                  className="w-16 h-16 object-cover" 
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };
  
  

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.createdAt);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <div className="flex items-center gap-2 whitespace-nowrap">
        {formattedDate}
      </div>
    );
  };

  const handleFileUpload = (file) => {
    if (!file) return; // Ensure file exists
    floorPlanFormik.setFieldValue("files", file);
};

  

  useEffect(() => {
    if (selectedFloorPlan.id !== null) {
      updateFloorPlanFormik.setValues({
        title: selectedFloorPlan.title,
      });
    }
  }, [selectedFloorPlan]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="pt-5 px-4">
        <div className="flex justify-between">
          <h1 className="text-[24px] text-[#003049] font-semibold">
            Floor plan Management
          </h1>
          <div>
            <Button
              label="Add"
              className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
              onClick={() => setVisible(true)}
              icon="pi pi-plus"
            />
          </div>
        </div>
        <div className="bg-white rounded-md mt-5 text-[18px]">
          <div className="card">
            <DataTable
              value={FloorPlan}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
              header={header}
              globalFilter={filters.global.value}
              emptyText="No Floor plan found."
              filters={filters}
              rowClassName={(index) => {
                return {
                  "bg-gray-100": index + (1 % 2) === 0,
                };
              }}
            >
              <Column
                header="#"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "10%" }}
                body={countBodyTemplate}
              ></Column>
              <Column
                field="title"
                header="Title"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="url"
                header="url"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
                body={discriptionBodyTemplate}
              ></Column>
              <Column
                field="createdAt"
                header="CreatedAt"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={dateBodyTemplate}
              ></Column>
              <Column
                header="Action"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={actionBodyTemplate}
              ></Column>
            </DataTable>
            <Sidebar
              visible={visibleRight}
              position="right"
              onHide={() => setVisibleRight(false)}
              style={{ width: "30rem" }}
              header={customHeader}
            >
              <div className="mt-5">
                <form onSubmit={updateFloorPlanFormik.handleSubmit}>
                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="title"
                        name="title"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 "
                        onChange={updateFloorPlanFormik.handleChange}
                        onBlur={updateFloorPlanFormik.handleBlur}
                        value={updateFloorPlanFormik.values.title}
                      />
                      <label htmlFor="title" className="text-sm">
                        Title
                      </label>
                    </FloatLabel>
                    {updateFloorPlanFormik.touched.title &&
                      updateFloorPlanFormik.errors.title && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateFloorPlanFormik.errors.title}
                        </div>
                      )}
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                    disabled={updateFloorPlanLoading}
                  >
                    {updateFloorPlanLoading ? (
                      <i
                        className="pi pi-spin pi-spinner text-white"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </Sidebar>
            <Dialog
              header="Add floor plan"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => {
                setVisible(false);
                floorPlanFormik.resetForm();
              }}
            >
              <form onSubmit={floorPlanFormik.handleSubmit}>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="title"
                      name="title"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 "
                      onChange={floorPlanFormik.handleChange}
                      onBlur={floorPlanFormik.handleBlur}
                      value={floorPlanFormik.values.title}
                    />
                    <label htmlFor="title" className="text-sm">
                      Title
                    </label>
                  </FloatLabel>
                  {floorPlanFormik.touched.title && floorPlanFormik.errors.title && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {floorPlanFormik.errors.title}
                    </div>
                  )}
                  <div className="py-2 my-2">
                  <FileUploader onFileChange={handleFileUpload} createFloorPlanSuccess={createFloorPlanSuccess} multiple={true}/>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                  disabled={createFloorPlanLoading}
                >
                  {createFloorPlanLoading ? (
                    <i
                      className="pi pi-spin pi-spinner text-white"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            </Dialog>
          </div>
        </div>
      </div>
      <Dialog
                visible={confirmVisible}
                onHide={handleReject}
                header="Delete Confirmation"
                modal
                className="w-[400px]"
              >
                <p>Do you want to delete '{plan.name}'?</p>
      
                <div className="flex gap-5 justify-end mt-4">
                  <Button
                    label="No"
                    icon="pi pi-times"
                    onClick={handleReject}
                    className="btn focus:outline-none focus:ring-0 hover:bg-[#002f499e]"
                  />
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    onClick={handleDelete}
                    className="bg-red-500 text-white btn hover:bg-red-600 focus:outline-none focus:ring-0"
                    autoFocus
                  />
                </div>
              </Dialog>
    </>
  );
};
