import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/floor-plans`;

// Fetch all hoa
export const fetchPlan = async () => {
  try {
    const response = await axiosInstance.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch hoa by id
export const fetchFloorPlanById = async (hoaId) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/${hoaId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create a new hoa
export const createFloorPlan = async (hoaData) => {
  try {
    const formData = new FormData();
    formData.append("title", hoaData.title);
    if (hoaData.files) {
      formData.append("files", hoaData.files);
    }
    const response = await axiosInstance.post(API_URL, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update an hoa
export const updateFloorPlan = async ({ hoaId, hoaData }) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/${hoaId}`, hoaData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete an hoa
export const deleteFloorPlan = async (hoaId) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/${hoaId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};