import React, { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { FaImages, FaRegEdit } from "react-icons/fa";
import { RiImageAddFill } from "react-icons/ri";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Input } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IoIosSearch } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useDocs } from "../hooks/useDoc";
import  FileUploader  from "../components/FileUpload/FileUpload";
export const DocManagement = () => {
  const {
    docs,
    createHoa,
    createHoaLoading,
    updateHoa,
    deleteDocs,
    isLoading,
    error,
    deleteDocsLoading,
    createDocsSuccess,
    updateDocsSuccess,
    updateHoaLoading,
  } = useDocs();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [selectedEvent, setSelectedEvent] = useState({
    id: null,
    title: "",
    files: [],
  });
  const [eventId, setEventId] = useState(null);
  const [visibleRight, setVisibleRight] = useState(false);

  const docsFormik = useFormik({
    initialValues: {
      title: "",
      files: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (values) => {
      createHoa(values);
    },
  });

  useEffect(()=>{
    if(createDocsSuccess){
        setVisible(false);
        docsFormik.resetForm();
    }
  },[createDocsSuccess])

  const updateDocsFormik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (values) => {
      updateHoa({ hoaId: selectedEvent.id, hoaData: values });
      updateHoaLoading === false &&
        updateDocsSuccess &&
        (() => {
          setVisible2(false);
          updateDocsFormik.resetForm();
        })();
    },
  });

  const customFooter = (Id) => {
    return (
      <div className="flex gap-5 justify-end items-end">
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => confirmDialog({ visible: false })}
          className="btn focus:outline-none focus:ring-0 hover:bg-[#002f499e]"
        />
        {deleteDocsLoading ? (
          <i
            className="pi pi-spin pi-spinner text-red-500"
            style={{ fontSize: "2rem" }}
          ></i>
        ) : (
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={() => accept(Id)}
            className="bg-red-500 text-white btn hover:bg-red-600 focus:outline-none focus:ring-0"
            autoFocus
          />
        )}
      </div>
    );
  };

  const accept = (Id) => {
    deleteDocs(Id);
    deleteDocsLoading === false && confirmDialog({ visible: false });
  };
  const reject = () => {
    confirmDialog({ visible: false });
  };

  const confirm2 = (Id) => {
    confirmDialog({
      message: `Do you want to delete this document?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      footer: customFooter(Id),
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-end justify-end">
        <Input
          placeholder="Search"
          className="text-sm focus:ring-0 focus:outline-none"
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          prefix={<IoIosSearch />}
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex gap-5">
          <ConfirmDialog footer={{}} />
          <MdOutlineDeleteOutline
            onClick={() => confirm2(rowData.id)}
            size={24}
            className="text-red-600 hover:text-red-500 cursor-pointer"
          />
          <FaRegEdit
            onClick={() => {
              setVisibleRight(true);
              setSelectedEvent({
                id: rowData.id,
                title: rowData.title,
                files: rowData.files,
              });
            }}
            className="text-[#003049] hover:text-[#002f499e] cursor-pointer"
            size={24}
          />
        </div>
      </>
    );
  };

  const countBodyTemplate = (rowData) => {
    return docs.indexOf(rowData) + 1;
  };

  const header = renderHeader();
  const customHeader = () => (
    <div className="flex items-start justify-start">
      <h1 className="font-semibold">Update Document's information</h1>
    </div>
  );

  const discriptionBodyTemplate = (rowData) => {
    const maxLength = 30;
    const url = rowData.url;
    return (
      <span className="text-sm text-[#003049] underline cursor-pointer">
        {url.length > maxLength ? `${url.substring(0, maxLength)}...` : url}
      </span>
    );
  };

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.createdAt);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <div className="flex items-center gap-2 whitespace-nowrap">
        {formattedDate}
      </div>
    );
  };

  const handleFileUpload = (file) => {
    if (!file) return; // Ensure file exists
    docsFormik.setFieldValue("files", file);
};

  

  useEffect(() => {
    if (selectedEvent.id !== null) {
      updateDocsFormik.setValues({
        title: selectedEvent.title,
      });
    }
  }, [selectedEvent]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="pt-5 px-4">
        <div className="flex justify-between">
          <h1 className="text-[24px] text-[#003049] font-semibold">
            Document Management
          </h1>
          <div>
            <Button
              label="Add"
              className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
              onClick={() => setVisible(true)}
              icon="pi pi-plus"
            />
          </div>
        </div>
        <div className="bg-white rounded-md mt-5 text-[18px]">
          <div className="card">
            <DataTable
              value={docs}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
              header={header}
              globalFilter={filters.global.value}
              emptyText="No event found."
              filters={filters}
              rowClassName={(index) => {
                return {
                  "bg-gray-100": index + (1 % 2) === 0,
                };
              }}
            >
              <Column
                header="#"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "10%" }}
                body={countBodyTemplate}
              ></Column>
              <Column
                field="title"
                header="Title"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="url"
                header="url"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
                body={discriptionBodyTemplate}
              ></Column>
              <Column
                field="createdAt"
                header="CreatedAt"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={dateBodyTemplate}
              ></Column>
              <Column
                header="Action"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={actionBodyTemplate}
              ></Column>
            </DataTable>
            <Sidebar
              visible={visibleRight}
              position="right"
              onHide={() => setVisibleRight(false)}
              style={{ width: "30rem" }}
              header={customHeader}
            >
              <div className="mt-5">
                <form onSubmit={updateDocsFormik.handleSubmit}>
                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="title"
                        name="title"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 "
                        onChange={updateDocsFormik.handleChange}
                        onBlur={updateDocsFormik.handleBlur}
                        value={updateDocsFormik.values.title}
                      />
                      <label htmlFor="title" className="text-sm">
                        Title
                      </label>
                    </FloatLabel>
                    {updateDocsFormik.touched.title &&
                      updateDocsFormik.errors.title && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateDocsFormik.errors.title}
                        </div>
                      )}
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                    disabled={updateHoaLoading}
                  >
                    {updateHoaLoading ? (
                      <i
                        className="pi pi-spin pi-spinner text-white"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </Sidebar>
            <Dialog
              header="Add Document"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => {
                setVisible(false);
                docsFormik.resetForm();
              }}
            >
              <form onSubmit={docsFormik.handleSubmit}>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="title"
                      name="title"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 "
                      onChange={docsFormik.handleChange}
                      onBlur={docsFormik.handleBlur}
                      value={docsFormik.values.title}
                    />
                    <label htmlFor="title" className="text-sm">
                      Title
                    </label>
                  </FloatLabel>
                  {docsFormik.touched.title && docsFormik.errors.title && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {docsFormik.errors.title}
                    </div>
                  )}
                  <div className="py-2 my-2">
                  <FileUploader onFileChange={handleFileUpload} createDocsSuccess={createDocsSuccess}/>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                  disabled={createHoaLoading}
                >
                  {createHoaLoading ? (
                    <i
                      className="pi pi-spin pi-spinner text-white"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
};
