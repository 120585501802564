import React, {useState,useEffect} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../utils/axios";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
export const Login = ({ visible, onClose }) => {
  const [isSignIn,setIsSignIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const { login,loginLoading } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async(values) => {
      login(values,{
        onSuccess: () => {
          formik.resetForm();
          onClose();
        },
      })
      
    },
  });
  const signUpFormik = useFormik({
    initialValues:{
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Must include at least one uppercase letter")
      .matches(/[a-z]/, "Must include at least one lowercase letter")
      .matches(/[0-9]/, "Must include at least one number")
      .matches(/[!@#$%^&*]/, "Must include at least one special character").required("Required"),
      confirmPassword: Yup.string()
       .oneOf([Yup.ref('password'), null], 'Passwords must match')
       .required('Confirm Password is required'),
    }),
    onSubmit: async(values) => {
      setLoading((prev) => !prev);
      await axiosInstance.post(`/api/auth/register`, {
        username: values.username,
        email: values.email,
        password: values.password,
      }).then((res) => {
        toast.success(res.data.message);
        setTimeout(() =>{
          setLoading((prev) => !prev);
          signUpFormik.resetForm();
          formik.resetForm();
          setIsSignIn((prev)=> !prev);
        },3000)
      }).catch((err) => {
        setLoading((prev) => !prev);
        toast.error(err.response.data.message);
      })
    },
  });

  return (
    <div className=" flex justify-content-center">
      {isSignIn ? <Dialog
        header="Log In"
        headerClassName="text-center text-[10rem] py-3"
        visible={visible}
        onHide={() => {
          if (!visible) return;
          signUpFormik.resetForm();
          formik.resetForm();
        setLoading((prev) => !prev);
          onClose();
        }}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        focusOnShow={false}
      >
        <div className="mt-5">
          <form onSubmit={formik.handleSubmit} className="mx-auto">
            <div className="py-2 mb-2">
            <FloatLabel>
              <InputText
                id="email"
                name="email"
                type="email"
                className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <label htmlFor="email" className="text-sm">Email</label>
            </FloatLabel>
            {formik.touched.email && formik.errors.email ? (
              <div className="text-[#BA1500] text-sm">{formik.errors.email}</div>
            ) : null}
            </div>
            <div className="py-2 mb-2">
            <FloatLabel>
              <InputText
                id="password"
                type="password"
                name="password"
                className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:border-gray-500 focus:ring-0 focus:outline-none"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <label htmlFor="password" className="text-sm">Password</label>
            </FloatLabel>
            {formik.touched.password && formik.errors.password ? (
              <div className="text-[#BA1500] text-sm">{formik.errors.password}</div>
            ) : null}
            </div>
            <div>
            {loginLoading ?
              <div className="w-full flex justify-center items-center py-2 bg-[#BA1500] rounded-md"><i className="pi pi-spin pi-spinner text-white" style={{ fontSize: '2rem' }}></i></div>:
              <Button label="Log In" type="submit" className="w-full bg-[#BA1500] text-center text-white py-3"/>}
            </div>
          </form>
          <div className="w-full text-center py-2">
              <p>
                You don't have an account <button onClick={()=>setIsSignIn((prev)=> !prev)} className="text-red-600 text-sm">create an account</button>
              </p>
            </div>
        </div>
      </Dialog> : <Dialog
        header="Sign Up"
        headerClassName="text-center text-[10rem] py-3"
        visible={visible}
        onHide={() => {
          if (!visible) return;
          signUpFormik.resetForm();
          formik.resetForm();
        setLoading((prev) => !prev)
          onClose();
        }}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        focusOnShow={false}
      >
        <div className="mt-5">
          <form onSubmit={signUpFormik.handleSubmit} className="mx-auto">
          <div className="w-full grid grid-cols-2 gap-x-5 gap-y-3">
            <div className="py-2">
            <FloatLabel>
              <InputText
                id="username"
                name="username"
                className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                onChange={signUpFormik.handleChange}
                onBlur={signUpFormik.handleBlur}
                value={signUpFormik.values.username}
              />
              <label htmlFor="username" className="text-sm">username</label>
            </FloatLabel>
            {signUpFormik.touched.username && signUpFormik.errors.username ? (
              <div className="text-[#BA1500] text-sm">{signUpFormik.errors.username}</div>
            ) : null}
            </div>
            <div className="py-2">
            <FloatLabel>
              <InputText
                id="email"
                name="email"
                type="email"
                className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                onChange={signUpFormik.handleChange}
                onBlur={signUpFormik.handleBlur}
                value={signUpFormik.values.email}
              />
              <label htmlFor="email" className="text-sm">Email</label>
            </FloatLabel>
            {signUpFormik.touched.email && signUpFormik.errors.email ? (
              <div className="text-[#BA1500] text-sm">{signUpFormik.errors.email}</div>
            ) : null}
            </div>
            <div className="py-2">
            <FloatLabel>
              <InputText
                id="password"
                name="password"
                type="password"
                className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                onChange={signUpFormik.handleChange}
                onBlur={signUpFormik.handleBlur}
                value={signUpFormik.values.password}
              />
              <label htmlFor="password" className="text-sm">Password</label>
            </FloatLabel>
            {signUpFormik.touched.password && signUpFormik.errors.password ? (
              <div className="text-[#BA1500] text-sm">{signUpFormik.errors.password}</div>
            ) : null}
            </div>
            <div className="py-2">
            <FloatLabel>
              <InputText
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                onChange={signUpFormik.handleChange}
                onBlur={signUpFormik.handleBlur}
                value={signUpFormik.values.confirmPassword}
              />
              <label htmlFor="Confirm Password" className="text-sm">Confirm Password</label>
            </FloatLabel>
            {signUpFormik.touched.confirmPassword && signUpFormik.errors.confirmPassword ? (
              <div className="text-[#BA1500] text-sm">{signUpFormik.errors.confirmPassword}</div>
            ) : null}
            </div>
            </div>
            <div>
              {loading ?
              <div className="w-full flex justify-center items-center py-2 bg-[#BA1500] rounded-md"><i className="pi pi-spin pi-spinner text-white" style={{ fontSize: '2rem' }}></i></div>:
              <Button label="Sign Up" type="submit" className="w-full bg-[#BA1500] text-center text-white py-3"/>}
            </div>
          </form>
            <div className="w-full text-center py-2">
              <p>
                Already have account <button onClick={()=>setIsSignIn((prev)=> !prev)} className="text-red-600 text-sm">login</button>
              </p>
            </div>
        </div>
      </Dialog>}
    </div>
  );
};
