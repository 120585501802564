import React,{useEffect,useState} from "react";
import { Dialog } from 'primereact/dialog';
import { FaUserGroup, FaBookmark } from "react-icons/fa6";
import { useMotionValue, motion, animate } from "motion/react"
import { convertDate,convertTime } from "../helper";
import { useEvents } from "../hooks/useEvent";
import { getUser } from "../utils/authHelpers";
import toast from'react-hot-toast';

export const EventModal = ({ visible, onClose, data }) => {
    const { bookEvent,bookEventLoading } = useEvents();
    const token = localStorage.getItem("token");
    const count = useMotionValue(0);
    const [displayCount, setDisplayCount] = useState(0)

  useEffect(() => {
    const controls = animate(count, data?.event_bookings?.length, {
      duration: 5,
      onUpdate: (latest) => setDisplayCount(Math.round(latest)),
    });

    return () => controls.stop();
  }, []);
  const handleBookEvent = async() => {
    const user = getUser(token)
    if (!user) {
      toast.error("Please login to book an event");
      onClose();
      return;
    }
    bookEvent({userId:user.decodedToken.userId,eventId:data.id});
    bookEventLoading === false && onClose();
  }
    return (
        <div className="card flex justify-center mx-auto">
            <Dialog 
                header={data?.title} 
                visible={visible} 
                maximizable 
                style={{ width: '70vw' }} 
                onHide={onClose}
            >
                <div className="grid sm:grid-cols-1 lg:grid-cols-3 md:gap-4 w-full place-items-center">
                    {/* Left Column - Event Details */}
                    <div className="col-span-2">
                        <img 
                            src={data?.images[0].image_url} 
                            alt={data?.title} 
                            className="w-full h-[30rem] object-cover mb-4" 
                        />
                        <p className="text-gray-700">{convertDate(data?.date)}</p>
                        <p className="text-gray-700"> {convertTime(data?.time)}</p>
                        <p className="text-gray-500 mt-2">{data?.description}</p>
                    </div>

                    {/* Right Column - Map & Location */}
                    <div>
                        {/* Google Map (using latitude & longitude) */}
                        <iframe
                            className="w-full h-56 rounded-lg"
                            src={`https://www.google.com/maps?q=${encodeURIComponent(data?.coordinates)}&output=embed`}
                            allowFullScreen
                            loading="lazy"
                        ></iframe>

                        {/* When & Where Section */}
                        <div className="mt-4 bg-gray-100 border">
                           <div className="py-2 px-3 border-b">
                           <h3 className="text-lg font-semibold">When & Where</h3>
                           </div>
                            <div className="px-3 py-2 flex flex-col gap-2">
                            <p className="text-gray-700">{data?.state},</p>
                            <p className="text-gray-700">{data?.city},</p>
                            <p className="text-gray-700">{data?.country}</p>
                            <p className="text-gray-500">{convertDate(data?.date)}</p>
                            <p className="text-gray-500">{convertTime(data?.time)} (Local Time)</p>
                            </div>
                        </div>

                        <div className="mt-4">
                            <h3>People who attended</h3>
                            <div className="mt-2 flex gap-2 items-center">
                            <FaUserGroup className="text-red-600 border w-16 h-16 rounded-md p-2"/>
                            <motion.pre className="text-3xl">{displayCount}</motion.pre>
                            </div>
                        </div>
                        <div className="mt-2">
                            <button onClick={()=>handleBookEvent()} className="w-full bg-red-600 text-white py-2 px-4 rounded-md font-semibold flex items-center justify-center gap-2">
                            {bookEventLoading ? (
                      <i
                        className="pi pi-spin pi-spinner text-white"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    ) : (
                      <><FaBookmark /> <p>Book</p></>
                    )}
                         </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
