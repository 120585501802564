import React, {useMemo, useState, useEffect} from "react";
import { Card } from "../components/admin/card";
import { useProperties } from "../hooks/useProperty";
import { useUsers } from "../hooks/useUsers";
import { useEvents } from "../hooks/useEvent";
import { useBlogs } from "../hooks/useBlog";
export const Dashboard = () => {
  const { countByStatusData = [] } = useProperties();
  const { users, isLoading: usersLoading } = useUsers();
  const { events, isLoading: eventsLoading } = useEvents();
  const { blogs, isLoading: blogsLoading } = useBlogs();
  const [totalUsers, setTotalUsers] = useState(0)
  const [totalEvents, setTotalEvents] = useState(0)
  const [totalBlog, setTotalBlog] = useState(0)

  useEffect(() => {
    if(usersLoading === false && eventsLoading === false && blogsLoading === false) {
      setTotalUsers(users?.length)
      setTotalEvents(events?.length)
      setTotalBlog(blogs?.length)
    }
  }, [users, usersLoading,events,blogs,eventsLoading,blogsLoading])
  const formattedData = useMemo(() => {
      if (!Array.isArray(countByStatusData)) return [];
      const statusCounts = {};
  
      countByStatusData.forEach(({ status, count }) => {
        statusCounts[status] = (statusCounts[status] || 0) + count;
      });
  
      return Object.entries(statusCounts).map(([name, value]) => ({ name, value }));
    }, [countByStatusData]);

  // if (isLoading && usersLoading && eventsLoading && blogsLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="py-5 px-4">
      <div>
        <h1 className="text-[24px] text-[#003049] font-semibold">Dashboard</h1>
        <p className="text-gray-400">Welcome to Admin Panel</p>
      </div>
      <div className=" mt-10 grid lg:grid-cols-4 gap-4">
        <Card
          title="Total Community members"
          value={totalUsers}
          icon="pi pi-users"
        />
        {formattedData.length > 0 && formattedData.map((item, index)=>(
          <Card
            key={index}
            title={`Total Properties ${item.name === 'Rent' ? "For rent" : item.name === 'Sale' ? "For sale" : item.name}`}
            value={item.value}
            icon="pi pi-home"
          />
        ))}
        <Card
          title="Total Events"
          value={totalEvents}
          icon="pi pi-calendar"
        />
        <Card
          title="Total Blog Posts"
          value={totalBlog}
          icon="pi pi-flag"
        />
      </div>

    </div>
  );
};
