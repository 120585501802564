import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { createHoa,deleteHoa, fetchHoas, updateHoa } from "../api/docApi";


export const useDocs = () => {
    const queryClient = useQueryClient();

    // Fetch all hoas
    const { data: docs, error, isLoading } = useQuery({
        queryKey: ["docs"],
        queryFn: fetchHoas,
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to fetch Doc");
        },
    });

    // Create a new hoa
    const createHoaMutation = useMutation({
        mutationFn: createHoa,
        onSuccess: (data) => {
            toast.success(data.message || "Doc created successfully!");
            queryClient.invalidateQueries(["hoas"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to create Doc");
        },
    });

    // Update an hoa
    const updateHoaMutation = useMutation({
        mutationFn: updateHoa,
        onSuccess: (data) => {
            toast.success(data.message || "Doc updated successfully!");
            queryClient.invalidateQueries(["hoas"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to update Doc");
        },
    });

    // Delete an hoa
    const deleteMutation = useMutation({
        mutationFn: deleteHoa,
        onSuccess: (data) => {
            toast.success(data.message || "Doc deleted successfully!");
            queryClient.invalidateQueries(["hoas"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to delete document");
        },
    })

    return {
        docs,
        isLoading,
        error,
        createHoa: createHoaMutation.mutate,
        updateHoa: updateHoaMutation.mutate,
        deleteDocs: deleteMutation.mutate,
        deleteDocsLoading: deleteMutation.isPending,
        createDocsError: createHoaMutation.error,
        updateDocsError: updateHoaMutation.error,
        deleteDocsError: deleteMutation.error,
        createHoaLoading: createHoaMutation.isPending,
        updateHoaLoading: updateHoaMutation.isPending,
        createDocsSuccess: createHoaMutation.isSuccess,
        updateDocsSuccess: updateHoaMutation.isSuccess,
        deleteDocsSuccess: deleteMutation.isSuccess,
    }
}