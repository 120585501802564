import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axios";
import DOMPurify from "dompurify";

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axiosInstance.get(`/api/blogs/${id}`)
      .then(response => {
        setBlog(response.data);
        setLoading(false);
  
        // Ensure the related blogs fetch is called correctly
        const categoryId = response.data.category?.id || null;
        const labelIds = response.data.labels?.map(label => label.label.id) || [];
  
        fetchRelatedBlogs(categoryId, labelIds);
      })
      .catch(() => {
        setError("Failed to fetch blog details.");
        setLoading(false);
      });
  }, [id]);
  
  const fetchRelatedBlogs = (categoryId, labelIds) => {
    if (!categoryId && labelIds.length === 0) return;
  
    axiosInstance.get(`/api/blogs/related/categories`, {
      params: {
        category_id: categoryId,
        label_ids: labelIds.length > 0 ? labelIds.join(",") : null
      }
    })
      .then(response => {
        setRelatedBlogs(response.data.filter(blog => blog.id !== parseInt(id)));
      })
      .catch(() => {
        setRelatedBlogs([]);
      });
  };

  if (loading) return <p className="text-center text-red-900">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;
  if (!blog) return <p className="text-center text-red-500">No blog found.</p>;

  return (
    <div className="max-w-6xl mx-auto p-5 text-gray-900 flex flex-col lg:flex-row gap-6">
      {/* Main Blog Content */}
      <div className="lg:w-2/3">
        <h1 className="text-3xl font-bold text-center mb-4">{blog.title}</h1>
        <p className="text-center text-sm text-gray-500">
          Published on: {new Date(blog.createdAt).toLocaleDateString()}
        </p>
        
        {blog.images?.length > 0 && (
          <img
            src={blog.images[0].image_url}
            alt={blog.title}
            className="w-full h-80 object-cover rounded-lg shadow-md my-4"
          />
        )}
        
        {blog.category && (
          <p className="text-sm font-semibold text-gray-700">Category: {blog.category.name}</p>
        )}
        
        {blog.labels?.length > 0 && (
          <p className="text-sm text-gray-600">
            Labels: {blog.labels.map(label => label.label.name).join(", ")}
          </p>
        )}
        
        <div className="mt-4 text-gray-800 leading-relaxed" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.content) }} />

      </div>
      
      {/* Related Blogs */}
      <aside className="lg:w-1/3 bg-gray-50 p-4 rounded-md shadow-md">
        <h2 className="text-xl font-semibold mb-3 text-gray-800">Related Blogs</h2>
        {relatedBlogs.length > 0 ? (
          relatedBlogs.map((relatedBlog) => (
            <div key={relatedBlog.id} className="mb-4 cursor-pointer" onClick={() => navigate(`/blog/${relatedBlog.id}`)}>
              <h3 className="text-md font-semibold text-blue-700 hover:underline">{relatedBlog.title}</h3>
              <p className="text-xs text-gray-500">{new Date(relatedBlog.createdAt).toLocaleDateString()}</p>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No related blogs available.</p>
        )}
      </aside>
    </div>
  );
};

export default BlogDetail;
