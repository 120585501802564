import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/roles`;

// Fetch all roles
export const fetchRoles = async () => {
  try {
    const response = await axiosInstance.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create a new role
export const createRole = async (name) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/`, name);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a role
export const updateRole = async ({ roleId, roleData }) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/${roleId}`, roleData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// change user role
export const changeUserRole = async ({ userId, roleName }) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/change-role/${userId}`, { roleName });
      return response.data;
    } catch (error) {
      throw error;
      
    }
};
// Delete a role
export const deleteRole = async (roleId) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/${roleId}`);
    return response.data;
  } catch (error) {
    throw error
  }
};
