import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion } from "framer-motion";
import Modal from "react-modal";
import {Link} from 'react-router-dom'
import axiosInstance from "../utils/axios";
import { Empty } from "antd";

const CommunityInfo = () => {
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [year, setYear] = useState(2024);
  const [totalHomes, setTotalHomes] = useState(0);
  const [activeNav, setActiveNav] = useState("community");
  
    const pathname = window.location.pathname
    const handleNavClick = (navItem) => {
      setActiveNav(navItem);
    };

  // Fetch community data from API
  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const response = await axiosInstance.get("/api/community");
        setCommunity(response.data);
      } catch (error) {
        console.error("Error fetching community:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCommunity();
  }, []);

  // const history = [
  //   { year: 2010, event: "Learstate Community was founded." },
  //   { year: 2012, event: "First 100 homes were completed." },
  //   { year: 2015, event: "Community park and clubhouse were inaugurated." },
  //   { year: 2018, event: "Added gym and swimming pool for residents." },
  //   { year: 2021, event: "Expanded with 200 new homes and red spaces." },
  //   { year: 2023, event: "Smart community initiatives introduced." },
  // ];

  // Animated countdown for the year
  useEffect(() => {
    if (community?.establishedYear) {
      let count = 2024;
      const yearInterval = setInterval(() => {
        if (count > community.establishedYear) {
          count -= 1;
          setYear(count);
        } else {
          clearInterval(yearInterval);
        }
      }, 100);
      return () => clearInterval(yearInterval);
    }
  }, [community]);

  // Animated count-up for total homes
  useEffect(() => {
    if (community?.totalHomes) {
      let count = 0;
      const homeInterval = setInterval(() => {
        if (count < community.totalHomes) {
          count += 5;
          setTotalHomes(count);
        } else {
          setTotalHomes(community.totalHomes);
          clearInterval(homeInterval);
        }
      }, 50);
      return () => clearInterval(homeInterval);
    }
  }, [community]);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  if (loading) return <p className="text-center text-lg">Loading...</p>;

  return (
    <div className="mx-auto max-w-screen-xl text-[#003049] px-4 lg:px-8">
      <dv className = "mb-2">
        <ul
          // onMouseEnter={() => handleMouseEnter("community")}
          // onMouseLeave={handleMouseLeave}
          className="w-full flex flex-col md:flex-row gap-1 lg:gap-3 items-center justify-center whitespace-nowrap text-[7px] md:text-[10px] py-2 lg:text-[13px] bg-white text-black rounded-md font-bold shadow-md"
        >
          <li>
            <Link
              to="/events"
              className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-1 uppercase  block ${
                activeNav === "events" ? "bg-[#BA1500] hover:text-white" : ""
              }`}
              onClick={() => handleNavClick("events")}
            >
              calendar
            </Link>
          </li>

          <li>
            <Link
              to="/Board-members"
              className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-1 uppercase  block  ${
                activeNav === "members" ? "bg-[#BA1500] text-white" : ""
              }`}
              onClick={() => handleNavClick("members")}
            >
              board members
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-1 uppercase  block  ${
                activeNav === "news" ? "bg-[#BA1500] text-white" : ""
              }`}
              onClick={() => handleNavClick("news")}
            >
              news
            </Link>
          </li>
          <li>
            <Link
              to="/Floor-Plans"
              className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-1 uppercase  block  ${
                activeNav === "floor-plan" ? "bg-[#BA1500] text-white" : ""
              }`}
              onClick={() => handleNavClick("floor-plan")}
            >
              floor plan
            </Link>
          </li>
          <li>
            <Link
              to="/Rules-And-Regulations"
              className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-1 uppercase  block  ${
                activeNav === "docs" ? "bg-[#BA1500] text-white" : ""
              }`}
              onClick={() => handleNavClick("docs")}
            >
              rules & regulations
            </Link>
          </li>
        </ul>
      </dv>
      <motion.h1
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="lg:text-3xl text-2xl font-black mb-8 mt-2 text-center"
      >
        {community?.name ? `Welcome to ${community?.name}` : "Welcome"}
      </motion.h1>

      {community && <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.3 }}
        className="lg:w-2/4 w-full leading-relaxed text-[#003049] bg-white p-4 border-s-4 border-[#003049] text-center"
      >
        {community?.description}
      </motion.p>}

      <motion.div
        className="mt-6 grid grid-cols-1 gap-4 sm:mt-8 sm:grid-cols-2 lg:grid-cols-4"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 1,
            transition: { staggerChildren: 0.3 },
          },
        }}
      >
        <motion.div
          whileHover={{ scale: 1.05 }}
          className="flex flex-col rounded-lg bg-red-50 px-4 py-8 text-center"
        >
          <dd className="text-4xl font-extrabold text-red-600 md:text-5xl">
            {year}
          </dd>
          <dt className="order-last text-lg font-medium text-gray-500">
            Year Established
          </dt>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.05 }}
          className="flex flex-col rounded-lg bg-red-50 px-4 py-8 text-center"
        >
          <dd className="text-4xl font-extrabold text-red-600 md:text-5xl">
            {totalHomes}
          </dd>
          <dt className="order-last text-lg font-medium text-gray-500">
            Total Homes
          </dt>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.05 }}
          className="flex flex-col rounded-lg bg-red-50 px-4 py-8 text-center"
        >
          <dd className="text-xl font-bold text-red-600">
            {community?.amenities?.join(", ") || "N/A"}
          </dd>
          <dt className="order-last text-lg font-medium text-gray-500">
            Amenities
          </dt>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.05 }}
          className="flex flex-col rounded-lg bg-red-50 px-4 py-8 text-center"
        >
          <dd className="text-xl font-bold text-red-600">
            Monthly Gatherings, Sports, Festivals
          </dd>
          <dt className="order-last text-lg font-medium text-gray-500">
            Community Events
          </dt>
        </motion.div>
      </motion.div>

      {/* <div className="relative w-full max-w-5xl mx-auto py-12">
      <h3 className="text-2xl font-bold text-center mb-24">Community History</h3>
      
      <div className="relative p-16 flex items-center">
       
        <div className="absolute top-1/2 w-full h-1 bg-red-500 -translate-y-1/2" />
        
       
        {history.map((item, index) => (
          <motion.div 
            key={index}
            className="relative w-1/6 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="absolute top-1/2 left-1/2 w-16 h-1 bg-red-600 -translate-x-1/2 -translate-y-1/2" />
            
            <div className="flex items-center justify-center w-8 h-8 bg-red-600 text-white rounded-full text-white mx-auto relative z-10">
            <CheckCircle size={20} />
            </div>
            
            <div
              className={`absolute w-40 p-3 bg-white text-left border-2 border-dotted  border-l-red-600 rounded-lg text-sm font-semibold ${
                index % 2 === 0 ? "-top-36" : "top-12"
              } left-1/2 transform -translate-x-1/2`}
            >
            <p className="mt-4 text-sm font-medium text-gray-500">{item.year}</p>
              {item.event}
            </div>
            
           
          </motion.div>
        ))}
      </div>
    </div> */}

      <div className="mt-12">
        <h3 className="text-2xl font-bold text-[#003049] text-center">
          Community Gallery
        </h3>
        {community ? (
          <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {community?.images?.map((image, index) => (
              <motion.img
                key={index}
                src={image.imageUrl}
                alt={`Community view ${index + 1}`}
                className="rounded-lg shadow-lg cursor-pointer"
                whileHover={{ scale: 1.05 }}
                onClick={() => openModal(image.imageUrl)}
              />
            ))}
          </div>
        ) : (
          <motion.div className="w-full flex justify-center items-center">
            <Empty description="No image available" />
          </motion.div>
        )}
      </div>

      {/* Image Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
      >
        <div className="relative max-w-3xl w-full">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white text-2xl"
          >
            X
          </button>
          <Carousel
            showThumbs={false}
            infiniteLoop
            autoPlay
            selectedItem={community?.images?.findIndex(
              (img) => img.imageUrl === selectedImage
            )}
          >
            {community?.images?.map((image, index) => (
              <div key={index}>
                <img
                  src={image.imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="rounded-lg shadow-lg"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </div>
  );
};

export default CommunityInfo;
