import { useState, useEffect } from "react";
import { DropdownMultipleSelection } from "../components/DropDown/DropDown";
import { Accordion, AccordionTab } from "primereact/accordion";
import background from "../components/assets/breno-assis-r3WAWU5Fi5Q-unsplash.jpg";
import { useEmail } from "../hooks/useEmail";
import { Dialog } from "primereact/dialog";

export const Contact = () => {
  const { sendEmailContact, sendEmailLoading, sendEmailSuccess } = useEmail();
  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    preferredTitle: "Mr.",
    cellPhone: "",
    email: "",
    subject: "",
    howDidYouFindUs: "",
    additionalInfo: "",
  });

  const subjects = [
    "I would like to sell my home",
    "I would like to buy a home",
    "I am looking for a rental",
    "I would like to rent my unit out",
    "I am looking for a property manager for my rental",
    "I would like to be notified when a new unit is listed",
    "I would like to be notified when a new unit is sold/rented",
    "I would like to receive community newsletter",
    "Other",
  ];

  const findingOptions = [
    "Internet search",
    "A friend/relative recommended",
    "On a printed advertisement",
    "On an online advertisement",
    "From a fridge magnet",
    "From the HOA management office",
    "From a real estate agent",
    "Other",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmailContact(formData);
  };

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;

    setFormData((prevData) => ({
      ...prevData,
      subject: selectedValue,
    }));
  };

  useEffect(() => {
    if (sendEmailSuccess) {
      setShowDialog(true);
      setTimeout(() => {
        setFormData({
          firstName: "",
          lastName: "",
          preferredTitle: "",
          cellPhone: "",
          email: "",
          subject: "",
          howDidYouFindUs: "",
          additionalInfo: "",
        });
        setShowDialog(false);
      }, 6000);
    }
  }, [sendEmailSuccess]);

  return (

    <div className="min-h-[30rem] place-content-center grid md:grid-flow-row">
     {!showDialog && <div className="w-full">
      <h2 className="text-xl font-bold mb-4 uppercase text-[#BA1500] text-center">Contact Form</h2>
      <div className="p-6 w-full  gap-4">
      <div>
          <div>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-2"
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-2"
            />
          </div>
          <div >
          <input
            type="text"
            name="cellPhone"
            placeholder="Cell Phone"
            value={formData.cellPhone}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-2"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-2"
          />
          </div>
          </div>
          <div>
          <label className="block mb-2 font-medium">Preferred Title:</label>
          {["Mr.", "Mrs.", "Ms.", "Miss"].map((title) => (
            <div key={title} className="flex items-center mb-2">
              <input
                type="radio"
                name="preferredTitle"
                value={title}
                
                onChange={handleChange}
                className="mr-2"
              />
              <span>{title}</span>
            </div>
          ))}
          
        </div>
        <div>
          <label className="block mb-2 font-medium">Subject:</label>
          <div className="w-full mb-2">
            <div className="space-y-2">
              {subjects.map((subject, index) => (
                <label
                  key={index}
                  className="flex items-center space-x-2 cursor-pointer"
                >
                  <input
                    type="radio"
                    name="subject"
                    value={subject}
                    onChange={handleSelectionChange}
                    className="form-radio text-blue-600"
                  />
                  <span>{subject}</span>
                </label>
              ))}
            </div>
          </div>
          <div>
          <label className="block mb-2 font-medium">How did you find us?</label>

          {findingOptions.map((option) => (
            <div key={option} className="flex items-center mb-2">
              <input
                type="radio"
                name="howDidYouFindUs"
                value={option}
                checked={formData.howDidYouFindUs === option}
                onChange={handleChange}
                className="mr-2"
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
        </div>
        <textarea
            name="additionalInfo"
            placeholder="Additional information you would like to add"
            value={formData.additionalInfo}
            onChange={handleChange}
            className="w-full p-2 border rounded mb-4"
          />
           <button
          type="submit"
          onClick={handleSubmit}
          className="w-full bg-[#BA1500] text-white py-2 rounded"
          disabled={sendEmailLoading}
        >
          {sendEmailLoading ? (
            <i className="pi pi-spin pi-spinner text-white"></i>
          ) : (
            "Send"
          )}
        </button>
      </div>
      </div>
      }
        {showDialog && <div className="text-2xl">
          <h1 className="text-3xl font-bold mb-3">THANK YOU</h1>
          <p>
            Thank you for completing the form, {formData?.preferredTitle} {formData?.lastName}.
          </p>
          <p>We will get back to you as soon as possible.</p>
          <p>
            American Realty Network Team
          </p>
        </div>}
    </div>
  );
};
