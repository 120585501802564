import { useState } from "react";
import { useMutation, useQuery,useQueryClient } from "@tanstack/react-query";
import { fetchProperties, countByStatus, searchProperty } from "../api/propertyApi";
import toast from 'react-hot-toast'

export const useProperties = () => {
    const [searchResults, setSearchResults] = useState(null);
    const queryClient = useQueryClient();

    const { data: properties, error, isLoading } = useQuery({
        queryKey: ['properties'],
        queryFn: fetchProperties,
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to fetch properties ��')
        }
    })

    const { data: countByStatusData, error: countByStatusError, isLoading: countByStatusLoading } = useQuery({
        queryKey: ['countByStatus'],
        queryFn: countByStatus,
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to fetch count by status')
        }
    })

    const searchPropertyNumberMutation = useMutation({
        mutationFn: searchProperty,
        onSuccess: (data) => {
            toast.success(data.message || 'Property found successfully!');
            setSearchResults(data);
            queryClient.invalidateQueries(['properties']);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to search property')
        }
    })

    return { 
        properties,
        isLoading,
        error,
        countByStatusData,
        countByStatusLoading,
        countByStatusError,
        searchPropertyNumber:searchPropertyNumberMutation.mutate,
        searchPropertyNumberLoading: searchPropertyNumberMutation.isPending,
        searchResults
     }
}