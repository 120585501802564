import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useProperties } from "../../../hooks/useProperty";

const RealEstateRecent = () => {
  const { properties = [] } = useProperties();

  const formattedProperties = properties.map((property) => ({
    id: property?.id,
    name: `${property?.unit_type} #${property?.unit_number}`,
    location: `${property?.city}, ${property?.state}`,
    price: `$${property?.price_or_rent}`,
    date: property?.createdAt?.split("T")[0],
  }));

  return (
    <div>
      <DataTable value={formattedProperties} paginator rows={5} height={"450px"} paginatorClassName="pagination">
        <Column field="name" header="Property Name" className="whitespace-nowrap" headerClassName="whitespace-nowrap"></Column>
        <Column field="location" header="Location" className="whitespace-nowrap" headerClassName="whitespace-nowrap"></Column>
        <Column field="price" header="Price" className="whitespace-nowrap" headerClassName="whitespace-nowrap"></Column>
        <Column field="date" header="Date" className="whitespace-nowrap" headerClassName="whitespace-nowrap"></Column>
      </DataTable>
    </div>
  );
};

export default RealEstateRecent;
