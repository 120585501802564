import {useState} from 'react'
import Logo from "./assets/logo.png";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";


const Footer = () => {
  const currentYear = new Date().getFullYear();
    const [activeDropdown, setActiveDropdown] = useState(null);

    const toggleDropdown = (menu) => {
      setActiveDropdown((prevMenu) => (prevMenu === menu ? null : menu));
    };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };
  return (
    <footer className=" bg-[#BA1500] text-white text-sm font-semibold flex md:flex-col justify-start items-start lg:justify-center lg:items-center w-full">
      <div className="container lg:mx-auto lg:px-4 lg:py-6">
        <div className="flex flex-col md:flex-row justify-between gap-6 pl-5 lg:pl-0">
          <div className="flex flex-col justify-start items-start lg:justify-center lg:items-center">
            <Link to="/">
              <h4 className="font-bold text-lg">
                American Realty Network LLC.
              </h4>
            </Link>
            <Link to="/">
              <img src={Logo} alt="Logo" className="h-16 w-16" />
            </Link>
            <p className="mt-2">433 Plaza Real, Suite 275</p>
            <p>Boca Raton, Florida, USA 33433</p>
          </div>
          <div className="lg:justify-items-center">
            <p className="font-semibold">Contact Information: </p>
            <p className="mt-2">
              <span className="font-semibold">Phone:</span> +1 561-481-8131
            </p>
            <p>
              <span className="font-semibold">Email:</span>{" "}
              <a
                href="mailto:miami23@EricNur.com"
                className="hover:underline font-semibold"
              >
                miami23@EricNur.com
              </a>
            </p>
          </div>

          <div className="justify-start items-start lg:text-center md:text-right lg:justify-items-center">
            <Link to="/" className="hover:underline block">
              HOME
            </Link>
            <div 
            className="relative"
            onMouseLeave={handleMouseLeave}
            >
            <div className='flex lg:justify-center items-center'>
            <Link
              to="/community"
              className="hover:underline"
              
            >
              <span>COMMUNITY</span>
            </Link>
            <IoMdArrowDropdown size={32}  onClick={() => toggleDropdown("community")} className="cursor-pointer"/>
            </div>
           
            {activeDropdown === "community" && (
              <ul
                // onMouseEnter={() => handleMouseEnter("community")}
                onMouseLeave={handleMouseLeave}
                className="absolute z-50 text-left pl-2 lg:w-full w-48 bg-white text-[#BA1500] shadow-md rounded-md"
              >
                <li>
                  <Link
                    to="/events"
                    className="hover:underline block"
                    
                  >
                    CALENDAR
                  </Link>
                </li>

                <li>
                  <Link
                    to="/board-members"
                    className="hover:underline block"
                  >
                    BOARD MEMBERS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className="hover:underline block"
                  >
                    NEWS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/floor-plan"
                    className="hover:underline block"
                    
                  >
                    ⁠FLOOR PLANS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/docs"
                    className="hover:underline block"
                  >
                    RULES & REGULATIONS
                  </Link>
                </li>
              </ul>
            )}
            </div>
            <Link to="/ARN-Team" className="hover:underline  block">
              ARN TEAM
            </Link>
            <Link to="/contact" className="hover:underline  block">
              CONTACT US
            </Link>
            <Link to="/Terms-Of-Use" className="hover:underline block">
              TERMS OF USE
            </Link>
            <Link to="/Privacy-Policy" className="hover:underline block">
              PRIVACY POLICY
            </Link>
          </div>
        </div>

        <div className="border-t border-white mt-4 pt-4 text-center text-xs">
          <p>
            Information obtained from MLS, public records, and other sources is
            believed to be correct but not warranted. This is not the official
            website of YARCOBR.
          </p>
          <p className="mt-2">
            &copy; {currentYear} American Realty Network LLC. All Rights
            Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
