import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/properties`;

// Fetch all properties
export const fetchProperties = async () => {
  try {
    const response = await axiosInstance.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const countByStatus = async () =>{
    try {
        const response = await axiosInstance.get(`${API_URL}/count-by-status`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const searchProperty = async ({propertyNumber}) =>{
    try {
        const response = await axiosInstance.get(`${API_URL}/properties/${propertyNumber}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}