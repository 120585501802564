import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {createFloorPlan, deleteFloorPlan, fetchPlan, updateFloorPlan} from "../api/FloorPlanApi"


export const useFloorPlan = () => {
    const queryClient = useQueryClient();

    // Fetch all hoas
    const { data: FloorPlan, error, isLoading } = useQuery({
        queryKey: ["FloorPlan"],
        queryFn: fetchPlan,
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to fetch Doc");
        },
    });

    // Create a new hoa
    const createFloorPlanMutation = useMutation({
        mutationFn: createFloorPlan,
        onSuccess: (data) => {
            toast.success(data.message || "Doc created successfully!");
            queryClient.invalidateQueries(["FloorPlan"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to create Doc");
        },
    });

    // Update an hoa
    const updateFloorPlanMutation = useMutation({
        mutationFn: updateFloorPlan,
        onSuccess: (data) => {
            toast.success(data.message || "Doc updated successfully!");
            queryClient.invalidateQueries(["FloorPlan"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to update Doc");
        },
    });

    // Delete an hoa
    const deleteFloorPlanMutation = useMutation({
        mutationFn: deleteFloorPlan,
        onSuccess: (data) => {
            toast.success(data.message || "Doc deleted successfully!");
            queryClient.invalidateQueries(["FloorPlan"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to delete document");
        },
    })

    return {
        FloorPlan,
        isLoading,
        error,
        addFloorPlan: createFloorPlanMutation.mutate,
        updateFloorPlan: updateFloorPlanMutation.mutate,
        deleteFloorPlan: deleteFloorPlanMutation.mutate,
        deleteFloorPlanLoading: deleteFloorPlanMutation.isPending,
        createFloorPlanError: createFloorPlanMutation.error,
        updateFloorPlanError: updateFloorPlanMutation.error,
        deleteFloorPlanError: deleteFloorPlanMutation.error,
        createFloorPlanLoading: createFloorPlanMutation.isPending,
        updateFloorPlanLoading: updateFloorPlanMutation.isPending,
        createFloorPlanSuccess: createFloorPlanMutation.isSuccess,
        updateFloorPlanSuccess: updateFloorPlanMutation.isSuccess,
        deleteFloorPlanSuccess: deleteFloorPlanMutation.isSuccess,
    }
}