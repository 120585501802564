import { useState, useEffect } from "react";
import { useMotionValue, animate, motion } from "framer-motion";

export const MotionCount = ({ number, size }) => {
  const count = useMotionValue(0);
  const [displayCount, setDisplayCount] = useState(0);

  useEffect(() => {
    const controls = animate(count, number, {
      duration: 0.5,
      onUpdate: (latest) => setDisplayCount(Math.round(latest)),
    });

    return () => controls.stop();
  }, [number, count]);

  return (
    <motion.pre
      className={`${size}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {displayCount}
    </motion.pre>
  );
};
