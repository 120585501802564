import React, { useEffect, useState, useCallback } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Select, Space } from "antd";
import { Input } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { IoIosSearch } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { useUsers } from "../hooks/useUsers";
import { useRoles } from "../hooks/useRoles";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dialog } from "primereact/dialog";
import Password from "antd/es/input/Password";
const Users = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visibleRight, setVisibleRight] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: null,
    name: "",
    email: "",
  });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const {
    users,
    isLoading,
    error,
    deleteUser,
    updateUser,
    createUser,
    createUserLoading,
  } = useUsers();
  const {
    roles,
    isLoading: isLoadingRoles,
    error: errorRoles,
    changeUserRole,
    createRole,
    deleteRole,
    createRoleLoading,
    updateRoleLoading,
    deleteRoleLoading,
    changeUserRoleLoading,
  } = useRoles();

  const handleAccept = () => {
    if (selectedUser) {
      deleteUser(selectedUser.id);
    }
    setDialogVisible(false);
  };

  const handleReject = () => {
    setDialogVisible(false);
    setConfirmVisible(false);
    setSelectedUser({ id: null, name: "", email: "" });
  };

  const handleRoleAccept = () => {
    deleteRole(selectedUser.id);
    setConfirmVisible(false);
  };

  const confirmDelete = (userName, userId) => {
    setSelectedUser({ name: userName, id: userId });
    setDialogVisible(true);
  };

  const confirmDeleteRole = (roleName, roleId) => {
    setSelectedUser({ name: roleName, id: roleId });
    setConfirmVisible(true);
  };

  const updateUserFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      name: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      updateUser({ userId: selectedUser.id, userData: values });
      setSelectedUser({ id: null, name: "", email: "" });
      setVisibleRight(false);
    },
  });

  const newUserFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      role: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      name: Yup.string().required("Required"),
      role: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      createUser(values);
      newUserFormik.resetForm();
      setVisible2(false);
    },
  });

  const newRoleFormik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      createRole(values);
      newRoleFormik.resetForm();
      setVisible(false);
    },
  });

  useEffect(() => {
    if (selectedUser.id !== null) {
      updateUserFormik.setValues({
        name: selectedUser.name,
        email: selectedUser.email,
      });
    }
  }, [selectedUser]); // No need for setFormikValues here

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const roleOptions = roles?.map((role) => ({
    value: role.name,
    label: role.name === "super admin" ? "CW Super Admin" : role.name === "agent admin" ? "CW Agent Admin" : "CW Public User",
  }));

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-end justify-end">
        <Input
          placeholder="Search"
          className="text-sm focus:ring-0 focus:outline-none"
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          prefix={<IoIosSearch />}
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex gap-5">
          <MdOutlineDeleteOutline
            onClick={() => confirmDelete(rowData.name, rowData.id)}
            size={24}
            className="text-red-600 hover:text-red-500 cursor-pointer"
          />
          <FaUserEdit
            onClick={() => {
              setVisibleRight(true);
              setSelectedUser({
                id: rowData.id,
                name: rowData.name,
                email: rowData.email,
              });
            }}
            className="text-[#003049] hover:text-[#002f499e] cursor-pointer"
            size={24}
          />
        </div>
      </>
    );
  };
  const actionRoleBodyTemplate = (rowData) => {
    return (
      <>
        <div className="">
          <MdOutlineDeleteOutline
            onClick={() => confirmDeleteRole(rowData.name, rowData.id)}
            size={24}
            className="text-red-600 hover:text-red-500 cursor-pointer"
          />
        </div>
      </>
    );
  };

  const handleChange = (value, rowData) => {
    changeUserRole({ userId: rowData.id, roleName: value });
    rowData.role = value;
  };

  const roleBodyTemplate = (rowData) => {
    return (
      <Space wrap>
        <Select
          value={rowData.role}
          style={{ width: 120 }}
          onChange={(value) => handleChange(value, rowData)}
          options={roleOptions}
          loading={isLoadingRoles}
        />
      </Space>
    );
  };

  const roleNameTemplate = (rowData) =>{
    return rowData.name === "super admin" ? "CW Super Admin" : rowData.name === "agent admin" ? "CW Agent User" : "CW Public User";
  }

  const countBodyTemplate = (rowData) => {
    return users.indexOf(rowData) + 1;
  };

  const countRoleBodyTemplate = (rowData) => {
    return roles.indexOf(rowData) + 1;
  };

  const header = renderHeader();
  const customHeader = () => (
    <div className="flex items-start justify-start">
      <h1 className="font-semibold">Update user's information</h1>
    </div>
  );

  return (
    <>
      <div className="pt-5 px-4">
        <div className="flex justify-between">
          <div className="flex gap-2 justify-end items-center w-full">
            <Button
              label="Add user"
              className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
              onClick={() => setVisible2(true)}
              icon="pi pi-plus"
            />
            <Button
              label="Add role"
              className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
              onClick={() => setVisible(true)}
              icon="pi pi-plus"
            />
          </div>
        </div>
        <div className="bg-white p-3 rounded-md mt-5 text-[18px]">
          <h1 className="text-[24px] text-[#003049] font-semibold">Users</h1>
          <div className="card">
            <DataTable
              value={users}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
              header={header}
              globalFilter={filters.global.value}
              emptyText="No customers found."
              filters={filters}
              rowClassName={(index) => {
                return {
                  "bg-gray-100": index + (1 % 2) === 0,
                };
              }}
              responsive={true}
            >
              <Column
                header="#"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "10%" }}
                body={countBodyTemplate}
              ></Column>
              <Column
                field="name"
                header="Name"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="email"
                header="Email"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "40%" }}
              ></Column>
              <Column
                field="role"
                header="Role"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={roleBodyTemplate}
              ></Column>
              <Column
                header="Action"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={actionBodyTemplate}
              ></Column>
            </DataTable>
            <Sidebar
              visible={visibleRight}
              position="right"
              onHide={() => setVisibleRight(false)}
              style={{ width: "30rem" }}
              header={customHeader}
            >
              <div className="mt-5">
                <form onSubmit={updateUserFormik.handleSubmit}>
                  <div className="py-2 mb-2">
                    <FloatLabel>
                      <InputText
                        id="email"
                        name="email"
                        type="email"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateUserFormik.handleChange}
                        onBlur={updateUserFormik.handleBlur}
                        value={updateUserFormik.values.email}
                      />
                      <label htmlFor="email" className="text-sm">
                        Email
                      </label>
                    </FloatLabel>
                    {updateUserFormik.touched.email &&
                    updateUserFormik.errors.email ? (
                      <div className="text-[#BA1500] text-sm">
                        {updateUserFormik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="py-2 mb-2">
                    <FloatLabel>
                      <InputText
                        id="name"
                        name="name"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateUserFormik.handleChange}
                        onBlur={updateUserFormik.handleBlur}
                        value={updateUserFormik.values.name}
                      />
                      <label htmlFor="name" className="text-sm">
                        Name
                      </label>
                    </FloatLabel>
                    {updateUserFormik.touched.name &&
                    updateUserFormik.errors.name ? (
                      <div className="text-[#BA1500] text-sm">
                        {updateUserFormik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#BA1500] text-center text-white py-3"
                  >
                    Update
                  </button>
                </form>
              </div>
            </Sidebar>
            <Dialog
              header="Add New User"
              visible={visible2}
              style={{ width: "50vw" }}
              onHide={() => setVisible2(false)}
            >
              <form onSubmit={newUserFormik.handleSubmit}>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="name"
                      name="name"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={newUserFormik.handleChange}
                      onBlur={newUserFormik.handleBlur}
                      value={newUserFormik.values.name}
                    />
                    <label htmlFor="name" className="text-sm">
                      Name
                    </label>
                  </FloatLabel>
                  {newUserFormik.touched.name && newUserFormik.errors.name && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {newUserFormik.errors.name}
                    </div>
                  )}
                </div>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="email"
                      name="email"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={newUserFormik.handleChange}
                      onBlur={newUserFormik.handleBlur}
                      value={newUserFormik.values.email}
                    />
                    <label htmlFor="email" className="text-sm">
                      Email
                    </label>
                  </FloatLabel>
                  {newUserFormik.touched.email &&
                    newUserFormik.errors.email && (
                      <div
                        className="text-[#BA1500] text-sm"
                        aria-live="polite"
                      >
                        {newUserFormik.errors.email}
                      </div>
                    )}
                </div>
                <div className="py-2 my-2">
                  <select
                    name="role"
                    id="role"
                    className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                    onChange={newUserFormik.handleChange}
                    onBlur={newUserFormik.handleBlur}
                    value={newUserFormik.values.role}
                  >
                    <option value="">Select Role</option>
                    {roles
                      ?.filter((role) => role.name !== "super admin")
                      .map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                  </select>
                  {newUserFormik.touched.role && newUserFormik.errors.role && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {newUserFormik.errors.role}
                    </div>
                  )}
                </div>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="password"
                      name="password"
                      type="password"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={newUserFormik.handleChange}
                      onBlur={newUserFormik.handleBlur}
                      value={newUserFormik.values.password}
                    />
                    <label htmlFor="password" className="text-sm">
                      Password
                    </label>
                  </FloatLabel>
                  {newUserFormik.touched.password &&
                    newUserFormik.errors.password && (
                      <div
                        className="text-[#BA1500] text-sm"
                        aria-live="polite"
                      >
                        {newUserFormik.errors.password}
                      </div>
                    )}
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                  disabled={
                    newUserFormik.isSubmitting || !newUserFormik.isValid
                  }
                >
                  {createUserLoading ? (
                    <i
                      className="pi pi-spin pi-spinner text-white"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  ) : (
                    "Add user"
                  )}
                </button>
              </form>
            </Dialog>
            
          </div>
        </div>
        <div className="bg-white p-3 rounded-md mt-5 text-[18px]">
          <h1 className="text-[24px] text-[#003049] font-semibold">Role</h1>
          <div className="card">
            <DataTable
              value={roles}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
              header={header}
              globalFilter={filters.global.value}
              emptyText="No role found."
              filters={filters}
              rowClassName={(index) => {
                return {
                  "bg-gray-100": index + (1 % 2) === 0,
                };
              }}
              responsive={true}
            >
              <Column
                header="#"
                headerClassName="bg-[#003049] text-white"
                body={countRoleBodyTemplate}
              ></Column>
              <Column
                field="name"
                header="Name"
                headerClassName="bg-[#003049] text-white"
                body={roleNameTemplate}
              ></Column>
              <Column
                header="Action"
                headerClassName="bg-[#003049] text-white"
                body={actionRoleBodyTemplate}
              ></Column>
            </DataTable>
            <Dialog
              header="Add New Role"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => setVisible(false)}
            >
              <form onSubmit={newRoleFormik.handleSubmit}>
                <div className="py-2 my-2">
                  <select
                    name="name"
                    id="role"
                    onBlur={newRoleFormik.handleBlur}
                    value={newRoleFormik.values.name}
                    onChange={newRoleFormik.handleChange}
                    className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                  >
                    <option value="">Select Role</option>
                      <option value="super admin">
                        CW Super Admin
                      </option>
                      <option value="agent admin">
                        CW Agent User
                      </option>
                      <option value="public">CW Public User</option>
                  </select>
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                  disabled={
                    newRoleFormik.isSubmitting || !newRoleFormik.isValid
                  }
                >
                  {createRoleLoading ? (
                    <i
                      className="pi pi-spin pi-spinner text-white"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            </Dialog>
          </div>
        </div>
        <Dialog
          visible={dialogVisible}
          onHide={handleReject}
          header="Delete Confirmation"
          modal
          className="w-[400px]"
        >
          <p>Do you want to delete '{selectedUser?.name}'?</p>

          <div className="flex gap-5 justify-end mt-4">
            <Button
              label="No"
              icon="pi pi-times"
              onClick={handleReject}
              className="btn focus:outline-none focus:ring-0 hover:bg-[#002f499e]"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={handleAccept}
              className="bg-red-500 text-white btn hover:bg-red-600 focus:outline-none focus:ring-0"
              autoFocus
            />
          </div>
        </Dialog>
        <Dialog
          visible={confirmVisible}
          onHide={handleReject}
          header="Delete Confirmation"
          modal
          className="w-[400px]"
        >
          <p>Do you want to delete '{selectedUser?.name}'?</p>

          <div className="flex gap-5 justify-end mt-4">
            <Button
              label="No"
              icon="pi pi-times"
              onClick={handleReject}
              className="btn focus:outline-none focus:ring-0 hover:bg-[#002f499e]"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={handleRoleAccept}
              className="bg-red-500 text-white btn hover:bg-red-600 focus:outline-none focus:ring-0"
              autoFocus
            />
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Users;
