import React from 'react'
import {MotionCount} from '../../../helper/motionCount'

export const Card = ({title,value,icon}) => {
  return (
    <div className='bg-white py-2 px-2 rounded-md shadow-sm'>
        <div className='flex justify-between items-center'>
            <h1 className='text-sm font-semibold text-[#003049]'>
                {title}
            </h1>
            <div className='mr-2'>
            <span className={`${icon} text-[24px]`}></span>
            </div>
        </div>
        <div>
            <p className='text-gray-500'>
                <MotionCount number={value} size="text-sm"/>
            </p>
        </div>
    </div>
  )
}
