

import React from "react";
import Header from './Header';
import Footer from './Footer';

const TermsAndConditions = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="max-w-5xl w-full bg-white shadow-md rounded-lg overflow-hidden">
        <header className="bg-[#BA1500] text-white py-6 px-8">
          <h1 className="text-3xl font-bold">Website Terms and Conditions</h1>
          <p className="text-sm mt-2">
            Please read these terms and conditions carefully before using this website.
          </p>
        </header>

        <main className="p-8 space-y-8">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Conditions of Use</h2>
            <p className="text-gray-700 leading-relaxed">
              By using this website, you certify that you have read and reviewed this Agreement
              and that you agree to comply with its terms. If you do not want to be bound by the
              terms of this Agreement, you are advised to stop using the website accordingly.
              American Realty Network LLC only grants use and access of this website, its
              products, and its services to those who have accepted its terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Privacy Policy</h2>
            <p className="text-gray-700 leading-relaxed">
              Before you continue using our website, we advise you to read our "PRIVACY POLICY"
              regarding our user data collection. It will help you better understand our
              practices.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Age Restriction</h2>
            <p className="text-gray-700 leading-relaxed">
              You must be at least 18 (eighteen) years of age before you can use this website. By
              using this website, you warrant that you are at least 18 years of age and you may
              legally adhere to this Agreement. We assume no responsibility for liabilities
              related to age misrepresentation.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Intellectual Property</h2>
            <p className="text-gray-700 leading-relaxed">
              You agree that all materials, products, and services provided on this website are
              the property of American Realty Network LLC, its affiliates, directors, officers,
              employees, agents, suppliers, or licensors including all copyrights, trade secrets,
              trademarks, patents, and other intellectual property. You also agree that you will
              not reproduce or redistribute the American Realty Network LLC’s intellectual
              property in any way, including electronic, digital, or new trademark registrations.
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              You grant American Realty Network LLC a royalty-free and non-exclusive license to
              display, use, copy, transmit, and broadcast the content you upload and publish. For
              issues regarding intellectual property claims, you should contact the company in
              order to come to an agreement.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">User Accounts</h2>
            <p className="text-gray-700 leading-relaxed">
              As a user of this website, you may be asked to register with us and provide private
              information. You are responsible for ensuring the accuracy of this information, and
              you are responsible for maintaining the safety and security of your identifying
              information. You are also responsible for all activities that occur under your
              account or password. If you think there are any possible issues regarding the
              security of your account on the website, inform us immediately so we may address
              them accordingly. We reserve all rights to terminate accounts, edit or remove
              content and cancel orders at our sole discretion.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Applicable Law</h2>
            <p className="text-gray-700 leading-relaxed">
              By using this website, you agree that the laws of the Florida, USA, without regard
              to principles of conflict laws, will govern these terms and conditions, or any
              dispute of any sort that might come between American Realty Network LLC and you, or
              its business partners and associates.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Disputes</h2>
            <p className="text-gray-700 leading-relaxed">
              Any dispute related in any way to your use of this website or to products you
              purchase from us shall be arbitrated by state or federal court Florida, USA and you
              consent to exclusive jurisdiction and venue of such courts.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Indemnification</h2>
            <p className="text-gray-700 leading-relaxed">
              You agree to indemnify American Realty Network LLC and its affiliates and hold
              American Realty Network LLC harmless against legal claims and demands that may
              arise from your use or misuse of our services. We reserve the right to select our
              own legal counsel.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Limitation on Liability</h2>
            <p className="text-gray-700 leading-relaxed">
              American Realty Network LLC is not liable for any damages that may occur to you as
              a result of your misuse of our website.
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              American Realty Network LLC reserves the right to edit, modify, and change this
              Agreement at any time. We shall let our users know of these changes by updating this
              page. This Agreement is an understanding between American Realty Network LLC and the
              user, and this supersedes and replaces all prior agreements regarding the use of this
              website.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default TermsAndConditions;
