import React, { useEffect, useState } from "react";
import CustomTable from "./table";
import PropertyModel from "./PropertyMadel";
import { URL } from "../utils/axios";
const Table = () => {
  const [properties, setProperties] = useState([]); // Ensure properties starts as an array
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [propertyId, setPropertyId] = useState();
  const [details, setDetails] = useState([]);


  useEffect(() => {
    fetch(`${URL}/api/properties`) // Backend API endpoint
      .then((response) => response.json())
      .then((data) => {
        // Ensure data is an array before proceeding
        let arr = Array.isArray(data) ? data : [data];
        setProperties(arr);
      })
      .catch((error) => console.error("Error fetching properties:", error));
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.toLowerCase());
  };

  const handleFilterChange = (e) => {
    console.log("filter", e);
    setFilterStatus(e);
  };

  const filteredProperties = properties.filter((property) => {
    const matchesSearch =
      searchQuery === "" ||
      Object.values(property).some((value) =>
        String(value).toLowerCase().includes(searchQuery)
      );
    const matchesFilter =
      filterStatus === "" || property.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  const openDetailModal = (property) => {
    setSelectedProperty(property);
    setShowModal(true);
  };

  const closeDetailModal = () => {
    setShowModal(false);
    setSelectedProperty(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  };

  function parseJsonFields(obj) {
    const parsedObj = {};

    for (const key in obj) {
      if (
        typeof obj[key] === "string" &&
        obj[key].startsWith("[") &&
        obj[key].endsWith("]")
      ) {
        try {
          parsedObj[key] = JSON.parse(obj[key]); // Convert JSON string to an array
        } catch (error) {
          console.error(`Error parsing ${key}:`, error);
          parsedObj[key] = obj[key];
        }
      } else {
        parsedObj[key] = obj[key];
      }
    }

    return parsedObj;
  }

  const formatArrayField = (value) => {
    if (Array.isArray(value)) return value.join(", "); // Convert array to a string with spaces
    if (typeof value === "string" && value.startsWith("[") && value.endsWith("]")) {
      try {
        return JSON.parse(value).join(", "); // Parse and format if it's a JSON string
      } catch {
        return value; // Return original if parsing fails
      }
    }
    return value;
  };

  const renderTable = (status) => {
    const statusFiltered = filteredProperties.filter(
      (property) => property.status === status
    );
    
    const handleRowClick = (property) => {
      setSelectedProperty(property);
      setPropertyId(property.id);
      setSelectedColumns(columns.map(col => col.dataIndex));
    
      // Generate details dynamically based on columns
      const updatedDetails = generateDetails(columns, property);
      setDetails(updatedDetails);
    
      setShowModal(true);
    };
    const columns = [
      ...(status === "Rented"
        ? [{
            title: "RENTED PRICE",
            key: "price_or_rent",
            dataIndex: "price_or_rent",
            type: "number",
          }]
        : status === "Sale"
        ? [{
            title: "ASKING PRICE",
            key: "price_or_rent",
            dataIndex: "price_or_rent",
            type: "number",
          }]
        : status === "Rent"
        ? [{
            title: "ASKING RENT",
            key: "price_or_rent",
            dataIndex: "price_or_rent",
            type: "number",
          }]
        : status === "Sold"
        ? [{
            title: "SOLD PRICE",
            key: "price_or_rent",
            dataIndex: "price_or_rent",
            type: "number",
          }]
        : []),
    
      { title: "BE", dataIndex: "bedrooms", key: "bedrooms", type: "number" },
      { title: "BA", dataIndex: "bathrooms", key: "bathrooms", type: "number" },
      { title: "SF", dataIndex: "square_feet", key: "square_feet", type: "number" },
    
      // ...(status === "Rent" || status === "Rented"
      //   ? [{ title: "Rental TYPE", key: "rental_type", dataIndex: "rental_type", type: "string" }]
      //   : []),
    
        { title: "HOME TYPE", dataIndex: "home_type", key: "home_type", type: "string" },
    
      { title: "VIEW", key: "view", dataIndex: "view", type: "string" },
    
      // ...(status === "Sale" || status === "Sold" || status === "Rented"
      //   ? [{ title: "UNIT", dataIndex: "unit_number", key: "unit_number", type: "string" }]
      //   : []),
    
      { title: "BUILDING", dataIndex: "building", key: "building", type: "string" },
    
      ...(status === "Rented"
        ? [{
            title: "RENTED DATE",
            dataIndex: "date",
            key: "date",
            type: "string",
          }]
        : status === "Sold"
        ? [{
            title: "SOLD DATE",
            dataIndex: "date",
            key: "date",
            type: "string",
          }]
        : []),
    ];
    
    return (
      <div className="mb-12 relative">
        <h3 className="bg-red-600 text-white font-bold text-lg p-2">
          {['Rented',"Sold"].includes(status) ? `${status.toUpperCase()} IN YARCOBR` : `FOR ${status.toUpperCase()} IN YARCOBR`}
        </h3>
           <CustomTable status={status} columns={columns} dataSource={statusFiltered} onClickRow={(property) => handleRowClick(property)}/>
      </div>
    );
  };

  

  const generateDetails = (columns, parsedData) => {
    return columns.map((col) => ({
      label: col.title, // Ensure the label matches the column title
      id: col.dataIndex, // Match the dataIndex with table data
      value: formatArrayField(parsedData[col.dataIndex]) || "N/A", // Ensure safe access
    }));
  };
  

  const renderDetailModal = () => {
    if (!selectedProperty) return null;
    const parsedData = parseJsonFields(selectedProperty); // Ensure JSON-like strings are parsed
    return (
      <>
  <PropertyModel propertyId={propertyId}  data={details} onClose={()=>closeDetailModal()} propertyUrl={formatArrayField(parsedData.property_details_url)}/>
      </>
    );
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold text-center text-red-600 mb-2">
        YACHT AND RACQUET CLUB OF BOCA RATON{" "}
      </h2>
      <p className="text-center text-gray-700 mb-6">
        A luxury resort-style community with private marina and private beach
      </p>

      {renderTable("Sale")}
      {renderTable("Rent")}
      {renderTable("Sold")}
      {renderTable("Rented")}


      {showModal && selectedProperty && renderDetailModal()}
    </div>
  );
};

export default Table;
