import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { login } from "../api/authApi";
import { jwtDecode } from "jwt-decode";

export const useAuth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Fetch auth status from localStorage
  const { data: authData={ isAuthenticated: false, role: "" }, isLoading } = useQuery({
    queryKey: ["authStatus"],
    queryFn: () => {
      const token = localStorage.getItem("token");
      if (!token) return { isAuthenticated: false, role: "" };

      try {
        const decoded = jwtDecode(token);
        return { isAuthenticated: true, role: decoded.role };
      } catch (error) {
        return { isAuthenticated: false, role: "" };
      }
    }
  });

  // Login mutation
  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      toast.success(data.message || "Login successful!");
      localStorage.setItem("token", data.token);
      
      const decoded = jwtDecode(data.token);
      console.log(decoded.role);
      queryClient.setQueryData(["authStatus"], { isAuthenticated: true, role: decoded.role });

      setTimeout(() => {
        if (decoded.role === "super admin") {
          navigate("/admin");
        } else if (decoded.role === "agent admin") {
          navigate("/agent-admin");
        } else if(decoded.role === "public"){
          navigate("/user-dashboard");
        }
        else{
          navigate("/");
        }
      }, 500);
    },
    onError: (error) => {
      console.log("Login Mutation Error:", error.response?.data);
      toast.error(error.response?.data.message || "Login failed!");
    },
  });

  // Logout function
  const logout = () => {
    localStorage.removeItem("token");
    queryClient.setQueryData(["authStatus"], { isAuthenticated: false, role: "" });
    navigate("/");
  };

  return {
    isLoading,
    isAuthenticated: authData.isAuthenticated,
    role: authData.role,
    login: loginMutation.mutate,
    loginLoading: loginMutation.isPending,
    loginError: loginMutation.error,
    logout,
  };
};
