import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Input, Form, Upload } from "antd";
import { IoIosSearch } from "react-icons/io";
import { FilterMatchMode } from "primereact/api";
import { UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../utils/axios";
import toast from "react-hot-toast";

const ManageBoardMembers = () => {
  const [boardMembers, setBoardMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    bio: "",
    company: "",
    image: null,
  });
  const [visible, setVisible] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [fileList, setFileList] = useState([]); // Stores the selected image file
  const toast = React.useRef(null);

  useEffect(() => {
    axiosInstance
      .get("/api/team")
      .then((response) => {
        setBoardMembers(response.data);
        setLoading(false);
      })
      .catch(() => {
        setError("Failed to fetch board members.");
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = ({ file }) => {
    setFileList([file]); // Only store the last selected file
    setFormData({ ...formData, image: file });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex items-end justify-end">
      <Input
        placeholder="Search"
        className="text-sm focus:ring-0 focus:outline-none"
        value={globalFilterValue}
        onChange={onGlobalFilterChange}
        prefix={<IoIosSearch />}
      />
    </div>
  );

  const saveMember = async () => {
    const form = new FormData();
    form.append("fullName", formData.fullName);
    form.append("email", formData.email);
    form.append("phone", formData.phone);
    form.append("bio", formData.bio);
    form.append("company", formData.company);
    if (formData.image) {
      form.append("image", formData.image);
    }

    const request = editingId
      ? axiosInstance.put(`/api/team/${editingId}`, form)
      : axiosInstance.post("/api/team", form);

    request
      .then((response) => {
        setBoardMembers(
          editingId
            ? boardMembers.map((member) =>
                member.id === editingId ? response.data : member
              )
            : [...boardMembers, response.data]
        );
        setEditingId(null);
        setVisible(false);
        setFileList([]); // Reset image selection
        toast.success("Board member saved successfully")
      })
      .catch(() => {
        setError("Failed to save board member.");
        toast.error("Failed to save board member");
      });
  };

  const deleteMember = (id) => {
    axiosInstance
      .delete(`/api/team/${id}`)
      .then(() => {
        setBoardMembers(boardMembers.filter((member) => member.id !== id));
        toast.current.show({
          severity: "warn",
          summary: "Deleted",
          detail: "Board member deleted successfully",
          life: 3000,
        });
      })
      .catch(() => {
        setError("Failed to delete board member.");
        toast.error("Failed to delete board member");
      });
  };

  if (loading) return <p className="text-center text-green-900">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="p-5">
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold text-[#003049] mb-5">
          Team Members Management
        </h1>
        <div>
          <Button
            label="Add Member"
            icon="pi pi-plus"
            className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>

      <div className="bg-white rounded-md mt-5 text-[18px]">
        <div className="card">
          <DataTable
            header={renderHeader}
            globalFilter={filters.global.value}
            emptyText="No members found."
            filters={filters}
            value={boardMembers}
            paginator
            rows={5}
            tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
          >
            <Column
              field="imageUrl"
              header="Image"
              body={(rowData) => (
                <img
                  src={rowData.imageUrl}
                  alt={rowData.fullName}
                  className="w-16 h-16 rounded-full"
                />
              )}
              headerClassName="bg-[#003049] text-white"
            />
            <Column field="fullName" header="Name" headerClassName="bg-[#003049] text-white" />
            <Column field="email" header="Email" headerClassName="bg-[#003049] text-white" />
            <Column field="phone" header="Phone" headerClassName="bg-[#003049] text-white" />
            <Column field="company" header="Company" headerClassName="bg-[#003049] text-white" />
            <Column field="bio" header="Bio" headerClassName="bg-[#003049] text-white" />
            <Column
              header="Actions"
              headerClassName="bg-[#003049] text-white"
              body={(rowData) => (
                <>
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning mr-2"
                    onClick={() => {
                      setEditingId(rowData.id);
                      setFormData(rowData);
                      setVisible(true);
                    }}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => deleteMember(rowData.id)}
                  />
                </>
              )}
            />
          </DataTable>
        </div>
      </div>

      <Dialog header={editingId ? "Edit Member" : "Add Member"} visible={visible} style={{ width: "30vw" }} onHide={() => setVisible(false)}>
        <Form layout="vertical">
          {["fullName", "email", "phone", "company", "bio"].map((field) => (
            <Form.Item label={field.charAt(0).toUpperCase() + field.slice(1)} key={field}>
              <Input name={field} value={formData[field]} onChange={handleInputChange} />
            </Form.Item>
          ))}
          <Form.Item label="Upload Image">
            <Upload fileList={fileList} beforeUpload={() => false} onChange={handleFileUpload} maxCount={1} accept="image/*">
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
          </Form.Item>
          <Button label="Save" className="w-full bg-[#BA1500] text-white py-3" onClick={saveMember} />
        </Form>
      </Dialog>
    </div>
  );
};

export default ManageBoardMembers;
