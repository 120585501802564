import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup, useMap, LayersControl } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import PropertyModel from "./Mappropertymodel";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axios";


// Fix Leaflet missing icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Component to auto-adjust zoom to show all markers
const FitBounds = ({ properties }) => {
  const map = useMap();

  useEffect(() => {
    if (properties.length > 0) {
      const bounds = L.latLngBounds(
        properties.map((p) => p.coordinates.split(", ").map(Number))
      );
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [properties, map]);

  return null;
};

const PropertyMapSearch = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [polygonCoords, setPolygonCoords] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyImages, setPropertyImages] = useState({});
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    city: "",
    status: "",
    propertyType: "",
    minPrice: "",
    maxPrice: "",
    minBeds: "",
    maxBeds: "",
    minBaths: "",
    maxBaths: "",
  });

  useEffect(() => {
    axiosInstance
      .get("https://yarcobr-backend.onrender.com/api/properties")
      .then((response) => {
        setProperties(response.data);
        setFilteredProperties(response.data);
        setLoading(false);
      })
      .catch(() => {
        setError("Failed to fetch properties");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const imageRequests = properties.map(async (property) => {
        try {
          const response = await axiosInstance.get(`https://yarcobr-backend.onrender.com/api/images/${property.id}`);
          return { id: property.id, imageUrl: response.data[0]?.image_url || "https://via.placeholder.com/300" };
        } catch (error) {
          return { id: property.id, imageUrl: "https://via.placeholder.com/300" };
        }
      });
      const images = await Promise.all(imageRequests);
      const imageMap = images.reduce((acc, { id, imageUrl }) => ({ ...acc, [id]: imageUrl }), {});
      setPropertyImages(imageMap);
    };

    if (properties.length > 0) {
      fetchImages();
    }
  }, [properties]);

  // Convert property coordinates
  const getCoordinates = (property) => {
    if (!property.coordinates) return null;
    const coords = property.coordinates.split(", ").map(Number);
    return coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1]) ? coords : null;
  };

  const handleMarkerClick = (property) => {
    console.log("Clicked Property ID:", property.id); // Debugging log

    setSelectedProperty([
      { label: "City", value: property.city },
      { label: "State", value: property.state },
      { label: "Price", value: `$${property.price}` },
      { label: "Bedrooms", value: property.bedrooms },
      { label: "Bathrooms", value: property.bathrooms },
      { label: "ID", value: property.id },
    ]);
  };

  // Check if a point is inside a polygon
  const isPointInsidePolygon = (point, polygonCoords) => {
    if (!polygonCoords) return true;
    const latLng = L.latLng(point[0], point[1]);
    const polygon = L.polygon(polygonCoords);
    return polygon.getBounds().contains(latLng);
  };

  // Handle when a polygon is drawn
  const onPolygonDraw = (e) => {
    const { layer } = e;
    const latLngs = layer.getLatLngs()[0].map((latLng) => [latLng.lat, latLng.lng]); // Get polygon coordinates
    setPolygonCoords(latLngs); // Store polygon coordinates, NOT the layer

    const filtered = properties.filter((property) => {
      const coordinates = getCoordinates(property);
      return coordinates && isPointInsidePolygon(coordinates, latLngs);
    });

    setFilteredProperties(filtered);

    // Remove polygon from the map after selection
    setTimeout(() => {
      layer.remove();
    }, 100);
  };

  // Handle when the polygon is deleted (reset properties)
  const onPolygonDelete = () => {
    setPolygonCoords(null);
    setFilteredProperties(properties);
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Apply filters to properties
  useEffect(() => {
    let filtered = properties.filter((property) => {
      return (
        (filters.city ? property.city.toLowerCase().includes(filters.city.toLowerCase()) : true) &&
        (filters.status ? property.status.toLowerCase() === filters.status.toLowerCase() : true) &&
        (filters.propertyType ? property.unit_type.toLowerCase() === filters.propertyType.toLowerCase() : true) &&
        (filters.minPrice ? parseFloat(property.price_or_rent) >= parseFloat(filters.minPrice) : true) &&
        (filters.maxPrice ? parseFloat(property.price_or_rent) <= parseFloat(filters.maxPrice) : true) &&
        (filters.minBeds ? parseInt(property.bedrooms) >= parseInt(filters.minBeds) : true) &&
        (filters.maxBeds ? parseInt(property.bedrooms) <= parseInt(filters.maxBeds) : true) &&
        (filters.minBaths ? parseInt(property.bathrooms) >= parseInt(filters.minBaths) : true) &&
        (filters.maxBaths ? parseInt(property.bathrooms) <= parseInt(filters.maxBaths) : true) &&
        (searchTerm ? property.name.toLowerCase().includes(searchTerm.toLowerCase()) : true)
      );
    });

    setFilteredProperties(filtered);
  }, [filters, properties]);

  if (loading) return <p className="text-center text-gray-600">Loading properties...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="flex flex-col h-screen">
    {/* Header */}
    <header className="p-4 bg-Colorb text-white text-center text-xl font-bold">MAP SEARCH PAGE</header>
    
    {/* Search Bar */}
    <div className="p-4  text-center flex justify-between">
        <input 
          type="text" 
          placeholder="Search by property name" 
          className="w-1/2 p-2 border rounded" 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
        
      </div>

    <div className="flex flex-col md:flex-row h-full mb-10">
     <motion.div className="w-full md:w-1/3 p-6 m-2 border-2 border-gray-200 rounded-md overflow-y-auto">
     <button 
          className="p-2 m-2 bg-Colora text-white rounded" 
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? "Properties" : "Filters"}
        </button>
          {showFilters ? (
            <div>
            
        <input type="text" name="city" placeholder="City" className="w-full mb-2 p-2 border rounded" value={filters.city} onChange={handleFilterChange} />
        <select name="status" className="w-full mb-2 p-2 border rounded" value={filters.status} onChange={handleFilterChange}>
          <option value="">Status</option>
          <option value="Sale">For Sale</option>
          <option value="Rent">For Rent</option>
          <option value="Rented">Rented</option>
          <option value="Sold">Sold</option>
        </select>

        <select
          name="propertyType"
          className="w-full mb-2 p-2 border rounded"
          value={filters.propertyType}
          onChange={handleFilterChange}
        >
          <option value="">Property Type</option>
          <option value="Residential">Residential</option>
          <option value="Commercial">Commercial</option>
        </select>

        <input
          type="number"
          placeholder="Min Price"
          className="w-full mb-2 p-2 border rounded"
          value={filters.minPrice}
          onChange={handleFilterChange}
          name="minPrice"
        />

        <input
          type="number"
          placeholder="Max Price"
          className="w-full mb-2 p-2 border rounded"
          value={filters.maxPrice}
          onChange={handleFilterChange}
          name="maxPrice"
        />

        <input
          type="number"
          placeholder="Min Beds"
          className="w-full mb-2 p-2 border rounded"
          value={filters.minBeds}
          onChange={handleFilterChange}
          name="minBeds"
        />

        <input
          type="number"
          placeholder="Max Beds"
          className="w-full mb-2 p-2 border rounded"
          value={filters.maxBeds}
          onChange={handleFilterChange}
          name="maxBeds"
        />

        <input
          type="number"
          placeholder="Min Baths"
          className="w-full mb-2 p-2 border rounded"
          value={filters.minBaths}
          onChange={handleFilterChange}
          name="minBaths"
        />

        <input
          type="number"
          placeholder="Max Baths"
          className="w-full mb-2 p-2 border rounded"
          value={filters.maxBaths}
          onChange={handleFilterChange}
          name="maxBaths"
        />


        <button
          className="w-full bg-red-500 text-white p-2 rounded mt-4"
          onClick={() => setFilters({
            city: "",
            status: "",
            propertyType: "",
            minPrice: "",
            maxPrice: "",
            minBeds: "",
            maxBeds: "",
            minBaths: "",
            maxBaths: "",
          })}
        >
          Reset Filters
        </button>
            </div>
          ) : (
            filteredProperties.map(property => (
              <div key={property.id} className="relative max-w-xl rounded-lg border-b py-4 shadow-xs">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <img src={propertyImages[property.id] || "https://via.placeholder.com/300"} className="w-20 h-20 object-cover rounded-lg" alt="property" />

      <div>
      <h3 className="text-lg font-medium text-gray-900">{property.city}, {property.state}</h3>

      <p className="text-red-600 font-bold">${property.price_or_rent}</p>

        <div className="mt-6 sm:text-right">
        <button  onClick={() => navigate(`/propertyDetails/${property.id}`)} className="mt-4  bg-Colorb text-white p-2 rounded-lg hover:bg-Colorb transition">View</button>
        </div>
      </div>
    </div>
            </div>
            ))
          )}
        </motion.div>


      {/* Map Display */}
      <motion.div className="w-full md:w-2/3 h-full m-4 border-2 border-gray-200 rounded-md" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <MapContainer center={[25.7617, -80.1918]} zoom={12} className="h-full w-full">
          <LayersControl position="topright">
            {/* Streets Map */}
            <LayersControl.BaseLayer checked name="Streets">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>

            {/* Satellite Map */}
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
            </LayersControl.BaseLayer>

            {/* Terrain Map */}
            <LayersControl.BaseLayer name="Terrain">
              <TileLayer url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
            </LayersControl.BaseLayer>
          </LayersControl>

          <FitBounds properties={filteredProperties} />

          {/* Drawing Controls */}
          <FeatureGroup>
            <EditControl
              position="topright"
              draw={{
                rectangle: false,
                circle: false,
                marker: false,
                polyline: false,
                circlemarker: false,
                polygon: true,
              }}
              edit={{ remove: true }}
              onCreated={onPolygonDraw}
              onDeleted={onPolygonDelete}
            />
          </FeatureGroup>

          {/* Display Markers */}
          {filteredProperties.map((property) => {
            const coordinates = getCoordinates(property);
            return coordinates ? (
              <Marker key={property.id} position={coordinates}>
                <Popup minWidth={300} maxWidth={400}>
                  <PropertyModel property={property} />
                </Popup>
              </Marker>
            ) : null;
          })}
        </MapContainer>
        </motion.div>
    </div>
    </div>
  );
};

export default PropertyMapSearch;