import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/users`;

// Fetch all users
export const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get(API_URL);
  return response.data;
  } catch (error) {
   throw error;
  }
};

// Create a new user
export const createUser = async (userData) => {
  try {
    const response = await axiosInstance.post(API_URL, userData);
  return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a user
export const updateUser = async ({ userId, userData }) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/${userId}`, userData);
  return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a user
export const deleteUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/${userId}`);
  return response.data;
  } catch (error) {
    throw error;
  }
};
