import {URL} from '../utils/axios';
import axiosInstance from '../utils/axios';

const API_URL = `${URL}/api/auth/login`;


export const login =async({email, password})=> {
    try {
        const response = await axiosInstance.post(API_URL, {email, password});
        return response.data;
    } catch (error) {
        throw error;
    }
}