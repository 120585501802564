import React, { useState, useEffect } from "react";
import { IoFilter, IoReloadOutline } from "react-icons/io5";
import { Icon } from "@iconify-icon/react";
import { FaArrowDownShortWide, FaArrowUpShortWide } from "react-icons/fa6";
const CustomTable = ({ columns, dataSource, onClickRow, status }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeColumn, setActiveColumn] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [value, setValue] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [previousColumn, setPreviousColumn] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });


  const displayedData =
  sortedData.length > 0 ? sortedData :
  filteredData.length > 0 ? filteredData :
  dataSource;


    useEffect(() => {
      setFilteredData([]);
      setSortedData(sortConfig.key ? sortData(dataSource, sortConfig.key, sortConfig.direction) : dataSource);
    }, [dataSource]);
    
    
    const sortData = (data, key, direction) => {
      return [...data].sort((a, b) => {
        let aValue = a[key];
        let bValue = b[key];
    
        if (key === "date") {
          aValue = aValue ? new Date(aValue).getTime() : 0;
          bValue = bValue ? new Date(bValue).getTime() : 0;
        } else if (["price_or_rent"].includes(key)) {
          aValue = Number(aValue) || 0;
          bValue = Number(bValue) || 0;
        }
    
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      });
    };
    
    useEffect(() => {
      if (!displayedData || displayedData.length === 0) return;
    
      if (!sortConfig.key) {
        let key = "";
        let direction = "";
    
        if (status === "Sale" || status === "Rent") {
          key = "price_or_rent";
          direction = "asc";
        } else if (status === "Sold" || status === "Rented") {
          key = "date"; 
          direction = "desc"; 
        }
    
        if (key) {
          setSortConfig({ key, direction });
          setSortedData(sortData(displayedData, key, direction));
        }
      }
    }, [status, displayedData]);
    

    const handleSort = (sortKey) => {
      const newDirection = 
        sortConfig.key === sortKey && sortConfig.direction === "asc" ? "desc" : "asc";
    
      setSortConfig({ key: sortKey, direction: newDirection });
    
      setSortedData(prevSortedData => 
        sortData(prevSortedData.length ? prevSortedData : dataSource, sortKey, newDirection)
      );
    };
    


  const buttonData = [
    // Numeric filters
    {
      label: "Greater than or equal",
      filter: (columnKey, value) => (item) =>
        Number(item[columnKey]) >= Number(value),
    },
    {
      label: "Less than or equal",
      filter: (columnKey, value) => (item) =>
        Number(item[columnKey]) <= Number(value),
    },
    {
      label: "Equal",
      filter: (columnKey, value) => (item) =>
        Number(item[columnKey]) === Number(value),
    },
    {
      label: "Between",
      filter: (columnKey, value) => (item) => {
        const [min, max] = value.split(",").map(Number);
        return Number(item[columnKey]) >= min && Number(item[columnKey]) <= max;
      },
    },
    // string filter
    {
      label: "Contains",
      filter: (columnKey, value) => (item) =>
        item[columnKey].toLowerCase().includes(value.toLowerCase()),
    },
  ];


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  };

  return (
    <div className="overflow-y-auto max-h-[500px] shadow-md">
      <table className="w-full text-sm text-left border-collapse border border-black text-gray-500 rtl:text-right max-h-[500px]">
        <thead className="sticky top-0 z-10 bg-gray-50">
          <tr className="bg-blue-100">
            {columns &&
              columns.map((column) => (
                <th
                  className="px-3 py-3 font-bold text-blue-900 border-2 border-black "
                  
                >
                  <div className="flex gap-2 items-center">
                    {["ASKING PRICE","ASKING RENT"].includes(column.title) ? (
                      <div className="leading-tight w-full flex flex-col items-center">
                        <span className="uppercase tracking-wide">ASKING</span>
                        <span className="uppercase tracking-wide">{column.title === "ASKING PRICE" ? "PRICE" : "RENT"}</span>
                      </div>
                    ) : (
                      <p className="text-center w-full uppercase">{column.title}</p>
                    )}
                    <div className="flex relative justify-center items-center gap-1">
                      {["price_or_rent", "date"].includes(column.key) && (
                        <div className="flex items-center gap-1">
                          {/* Sorting for price_or_rent column */}
                          {column.key === "price_or_rent" &&
                            (sortConfig.key === "price_or_rent" ? (
                              sortConfig.direction === "asc" ? (
                                <FaArrowUpShortWide
                                  size={24}
                                  className="hover:bg-gray-200 p-1 rounded-full text-black cursor-pointer transition-transform ease-in-out delay-75"
                                  onClick={() => handleSort("price_or_rent")}
                                />
                              ) : (
                                <FaArrowDownShortWide
                                  size={24}
                                  className="hover:bg-gray-200 p-1 rounded-full text-black cursor-pointer transition-transform ease-in-out delay-75"
                                  onClick={() => handleSort("price_or_rent")}
                                />
                              )
                            ) : (
                              <FaArrowUpShortWide
                                size={24}
                                className="hover:bg-gray-200 p-1 rounded-full text-black cursor-pointer transition-transform ease-in-out delay-75"
                                onClick={() => handleSort("price_or_rent")}
                              />
                            ))}

                          {/* Sorting for createdAt column */}
                          {column.key === "date" &&
                            (sortConfig.key === "date" ? (
                              sortConfig.direction === "asc" ? (
                                <FaArrowUpShortWide
                                  size={24}
                                  className="hover:bg-gray-200 p-1 rounded-full text-black cursor-pointer transition-transform ease-in-out delay-75"
                                  onClick={() => handleSort("date")}
                                />
                              ) : (
                                <FaArrowDownShortWide
                                  size={24}
                                  className="hover:bg-gray-200 p-1 rounded-full text-black cursor-pointer transition-transform ease-in-out delay-75"
                                  onClick={() => handleSort("date")}
                                />
                              )
                            ) : (
                              <FaArrowUpShortWide
                                size={24}
                                className="hover:bg-gray-200 p-1 rounded-full text-black cursor-pointer transition-transform ease-in-out delay-75"
                                onClick={() => handleSort("date")}
                              />
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
        {displayedData.map((row, index) => (
          <tr
            key={row.id || index}
            className="border-b cursor-pointer hover:bg-gray-100 even:bg-green-100"
            onClick={() => onClickRow(row)}
          >
            {columns.map((column) => {
              const cellValue = row[column.dataIndex];
      
              return (
                <td
                  key={column.key}
                  className="px-6 py-4 whitespace-nowrap border border-black"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRow(row);
                  }}
                >
                  <div
                    className={`text-sm text-gray-900 ${
                      column.dataIndex === "price_or_rent"
                        ? "text-right"
                        : "text-center"
                    }`}
                  >
                    {column.dataIndex === "price_or_rent"
                      ? cellValue !== null && !isNaN(Number(cellValue))
                        ? `$ ${Number(cellValue).toLocaleString("en-US")}`
                        : "N/A"
                      : column.dataIndex === "date" && cellValue
                      ? formatDate(cellValue)
                      : ["bedrooms", "bathrooms", "square_feet"].includes(column.dataIndex)
                      ? `${Number(cellValue).toLocaleString("en-US")}`
                      : column.dataIndex === "view" && cellValue.toLowerCase() === "intracoastal" ? "intrac" : cellValue}
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      
      
      </table>
    </div>
  );
};

export default CustomTable;
