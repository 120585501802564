import axiosInstance from '../utils/axios';
import axios from 'axios';
import { Buffer } from 'buffer';

async function getAccessToken() {
    try {
        const response = await axiosInstance.post('/api/trestle/authenticate');
        return response.data.accessToken;
    } catch (error) {
        console.error('Error fetching access token:', error.message);
        throw new Error('Failed to fetch access token');
    }
}

function createApiInstance(baseURL, token) {
    return axios.create({
        baseURL,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

async function fetchProperty(listingKey) {
    try {
        const token = await getAccessToken();
        const propertyApi = createApiInstance('https://api-trestle.corelogic.com/trestle', token);

        const response = await propertyApi.get('/odata/Property', {
            params: { $filter: `ListingId eq '${listingKey}'` },
        });

        if (response.data?.value?.length > 0) {
            return response.data.value[0];
        }

        // Fallback to secondary API
        const secondApi = axios.create({
            baseURL: 'https://api.bridgedataoutput.com/api/v2',
        });
        const fallbackResponse = await secondApi.get(`/OData/miamire/Property`, {
            params: {
                access_token: '2ea786c97bf14ecce37d2fb7769b1663',
                $filter: `ListingId eq '${listingKey}'`,
            },
        });

        return fallbackResponse.data?.value?.[0] || null;
    } catch (error) {
        console.error(`Error fetching property for ListingKey ${listingKey}:`, error.message);
        throw new Error(`Failed to fetch property for ListingKey ${listingKey}`);
    }
}

async function fetchPropertyMedia(listingKey) {
    try {
        const token = await getAccessToken();
        const images = [];

        try {
            const mediaApi = axios.create({
                baseURL: 'https://api-trestle.corelogic.com/trestle',
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'arraybuffer', // Important for binary data
            });

            const response = await mediaApi.get(`/odata/Property('${listingKey}')/Media/All`);

            if (response.status === 200 && response.data) {
                const contentType = response.headers['content-type'] || '';
                const match = contentType.match(/boundary=(.*)/);
                const boundary = match ? match[1] : null;

                if (!boundary) {
                    console.warn('Boundary not found. Skipping binary processing.');
                } else {
                    const data = Buffer.from(response.data, 'binary').toString('binary');
                    const parts = data.split(`--${boundary}`).filter(part => part.trim() && part.trim() !== '--');

                    parts.forEach((part, index) => {
                        const [rawHeaders, binaryData] = part.trim().split('\r\n\r\n');
                        if (!binaryData) return;

                        // Parse headers
                        const headers = {};
                        rawHeaders.split('\r\n').forEach(line => {
                            const [key, value] = line.split(': ');
                            if (key && value) headers[key.trim()] = value.trim();
                        });

                        images.push({
                            contentId: headers['Content-ID'] || `unknown_${index}`,
                            contentType: headers['Content-Type'] || 'application/octet-stream',
                            binaryData,
                        });
                    });
                }
            }
        } catch (error) {
            console.error('Primary API failed:', error.message);
        }

        if (images.length === 0) {
            try {
                const fallbackApi = axios.create({
                    baseURL: 'https://api.bridgedataoutput.com/api/v2',
                });

                const response = await fallbackApi.get(`/OData/miamire/Property('${listingKey}')`, {
                    params: { access_token: `2ea786c97bf14ecce37d2fb7769b1663` },
                });

                if (response.status === 200 && response.data && response.data.Media) {
                    response.data.Media.forEach(media => {
                        images.push({ url: media.MediaURL });
                    });
                }
            } catch (error) {
                console.error('Secondary API failed:', error.message);
            }
        }

        return { images };
    } catch (error) {
        console.error('Error fetching property media:', error.message);
        return { images: [] };
    }
}



async function fetchPropertyAndMedia(listingKey) {
    try {
        const property = await fetchProperty(listingKey);
        console.log('Fetched Property:', property);

        const { images } = await fetchPropertyMedia(listingKey);
        console.log('Fetched Images:', images);

        return { property, images };
    } catch (error) {
        console.error('Error in fetching property and media:', error.message);
        throw error;
    }
}

export { getAccessToken, fetchProperty, fetchPropertyMedia, fetchPropertyAndMedia };