import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchUsers, createUser, updateUser, deleteUser } from "../api/usersApi";
import toast from "react-hot-toast";
export const useUsers = () => {
  const queryClient = useQueryClient();

  // Fetch users
  const { data: users, error, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  // Create a user
  const createUserMutation = useMutation({
    mutationFn: createUser,
    onSuccess: (data) => {
        toast.success(data.message ||"User created successfully! ✅");
        queryClient.invalidateQueries(["users"])
    },
    onError: (error) => {
        toast.error(error.response?.data?.message || error.response?.data?.error ||  "Failed to create user")
    }
  });

  // Update a user
  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: (data) => {
        toast.success(data.message || "User created successfully! ✅");
        queryClient.invalidateQueries(["users"])
    },
    onError: (error) => {
        toast.error(error.response?.data?.message || "Failed to update user")
    }
  });

  // Delete a user
  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: (data) => {
        toast.success(data.message ||"User deleted successfully! ✅");
        queryClient.invalidateQueries(["users"])
    },
    onError: (error) => {
        toast.error(error.response?.data?.message ||error.response?.data?.error || "Failed to delete user")
    }
  });

  return {
    users,
    isLoading,
    error,
    createUser: createUserMutation.mutate,
    updateUser: updateUserMutation.mutate,
    deleteUser: deleteUserMutation.mutate,
    createUserLoading: createUserMutation.isPending
  };
};
