import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Property from "../../components/property"
import Logo from "../../components/assets/logo.png";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Team from "../teammanager";
import {EventManagement} from '../eventManagement';
import ManageBlog from "../ManageBlog";
import { AgentDashboard } from "../agentDashboard";
import CommunityManagePage from "../communityManager";
import {FloorPlanManagement} from '../FloorPlanManagement'
import { DocManagement } from "../docManagement";
import ManageBoardMembers from "../boardMembers";


export const AgentAdmin = () => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {logout} = useAuth()
  const navigate = useNavigate();

  const handleLogOut = async() => {
    logout();
    navigate('/');
  }
  const renderContent = () => {
    switch (activeTab) {
      case "Dashboard":
        return <AgentDashboard />;
        break;
      case "Property":
        return <Property />;
        break;
      case "Team": 
        return <Team />;
        break;
      case "Calendar":
        return <EventManagement />;
        break;
     case "News":
          return <ManageBlog />;
        break;
      case "Floor Plan":
        return <FloorPlanManagement />;
        break;
      case "Doc":
        return <DocManagement />;
        break;

        case "community":
          return <CommunityManagePage />;

        break;

        case "Members":
          return <ManageBoardMembers />;
        break;

      default:
        return <div>Select a tab</div>;
    }
  };

  // Function to handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen font-Poppins lg:flex-row">
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      <aside
        className={`
          fixed 
          top-0 
          left-0 
          h-full 
          bg-red-600 
          text-white 
          shadow-md 
          w-52
          transform 
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} 
          transition-transform duration-300 
          z-30 
          lg:static 
          lg:translate-x-0 
          lg:w-52
        `}
      >
        {/* Logo and Title and close button on mobile */}
        <div className="flex items-center justify-between py-6 px-4">
          <div className="flex items-center">
            <img src={Logo} className="w-12 h-12 object-contain" alt="Logo" />
            <span className="text-lg font-semibold hidden lg:inline-block">
              AboutYARCOBR
            </span>
          </div>
          {/* Close button visible only on mobile */}
          <button
            className="lg:hidden text-white focus:outline-none"
            onClick={toggleSidebar}
            aria-label="Close Sidebar"
          >
            <Icon icon="mdi:close" className="text-2xl" />
          </button>
        </div>

        {/* Navigation */}
        <nav className="flex-grow space-y-2 px-2">
          {[
            {name: "Dashboard", icon:"material-symbols:dashboard-rounded"},
            { name: "Property", icon: "mdi:home" },
            { name: "Team", icon: "mdi:account-group" },
            { name: "Members", icon: "mdi:account-group" },
            { name: "Calendar", icon: "mdi:events" },
            { name: "News", icon: "mdi:blog" },
            { name: "Floor Plan", icon: "mdi:floor-plan" },          
            { name: "community", icon: "mdi:account-group" },
            { name: "Doc", icon: "mdi:document" }


          ].map(({ name, icon }) => (
            <button
              key={name}
              onClick={() => {
                setActiveTab(name);
                setIsSidebarOpen(false);
              }} // Close sidebar on mobile after selection
              className={`w-full flex items-center gap-4 px-4 py-3 rounded-lg text-sm font-medium transition ${
                activeTab === name
                  ? "bg-red-500 text-white"
                  : "hover:bg-red-700 hover:text-white"
              }`}
              aria-current={activeTab === name ? "page" : undefined}
            >
              <Icon icon={icon} className="text-xl" />
              <span className="ml-2">{name}</span>{" "}
              {/* Always show text on all screen sizes */}
            </button>
          ))}
        {/* Logout Button */}
        <button onClick={handleLogOut} className="w-full mx-auto space-y-2 flex items-center gap-4 px-4 py-3 text-sm rounded-md font-medium text-white hover:bg-red-700 hover:text-white transition">
          <Icon icon="mdi:logout" className="text-xl" />
          <span className="ml-2">Logout</span>
        </button>
        </nav>

      </aside>

      {/* Top Navigation Bar for Mobile */}
      <header className="flex items-center justify-between bg-red-600 text-white p-4 lg:hidden">
        <button
          onClick={toggleSidebar}
          className="focus:outline-none"
          aria-label="Open Sidebar"
        >
          <Icon icon="mdi:menu" className="text-2xl" />
        </button>
        <div className="flex items-center">
          <img src={Logo} className="w-8 h-8 object-contain" alt="Logo" />
          <span className="ml-2 text-lg font-semibold">AboutYARCOBR</span>
        </div>
        {/* Placeholder for alignment */}
        <div className="w-8 h-8"></div>
      </header>

      {/* Main Content */}
      <main className="flex-1 bg-gray-100 overflow-auto">
        <section className="rounded-lg">{renderContent()}</section>
      </main>
    </div>
  );
};