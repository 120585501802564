export const convertDate = (date) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  }

export const convertTime = (time) =>{
    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(time).toLocaleTimeString("en-US", options);
  }