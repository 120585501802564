import React, { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import {  FaRegEdit } from "react-icons/fa";
import { RiImageAddFill } from "react-icons/ri";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Input } from "antd";
import { FilterMatchMode } from "primereact/api";
import { IoIosSearch } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useEvents } from "../hooks/useEvent";
import { Calendar } from "primereact/calendar";
import LocationPicker from "../components/Map/LocationPicker";
import { useMap } from "react-leaflet/hooks";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { MapContainer, TileLayer } from "react-leaflet";
import { Accordion, AccordionTab } from "primereact/accordion";
import OverlappingImages from "../components/overlappingImages";
import FileUploadDialog from "../components/FileUpload/FileUploadDialog";
import { motion, AnimatePresence } from "framer-motion";
export const EventManagement = () => {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("next");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const {
    events,
    createEvent,
    createEventLoading,
    isLoading,
    error,
    addEventImages,
    addEventImagesLoading,
    updateEvent,
    updateEventLoading,
    deleteEvent,
    deleteEventLoading,
    createEventSuccess,
    addEventImagesSuccess,
    updateEventSuccess,
    addEventImagesReset
  } = useEvents();
  const [selectedEvent, setSelectedEvent] = useState({
    id: null,
    title: "",
    description: "",
    dates: "",
    time: "",
    state: "",
    city: "",
    zip_code: "",
    images: [],
  });
  const [eventId, setEventId] = useState(null);
  const [visibleRight, setVisibleRight] = useState(false);

  const eventFormik = useFormik({
    initialValues: {
      title: "",
      description: "",
      dates: "",
      time: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      coordinates: [], // Expecting an array of latitude & longitude
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      dates: Yup.date().required("Dates is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      zip_code: Yup.string().required("Zip Code is required"),
      time: Yup.string().required("Time is required"),
      coordinates: Yup.array()
        .of(Yup.number().required("Coordinates are required"))
        .min(2, "Latitude and Longitude are required"),
    }),
    onSubmit: (values) => {
      createEvent(values)
      !createEventLoading && createEventSuccess && (() => {
        setVisible(false);
        eventFormik.resetForm();
    })();    
    },
  });

  const updateEventFormik = useFormik({
    initialValues: {
      title: "",
      description: "",
      dates: "",
      time: "",
      state: "",
      city: "",
      zip_code: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      dates: Yup.date().required("Dates is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      zip_code: Yup.string().required("Zip Code is required"),
      time: Yup.string().required("Time is required"),
    }),
    onSubmit: (values) => {
      updateEvent({ eventId: selectedEvent.id, eventData: values });
      !updateEventLoading && updateEventSuccess && (()=>{
        setVisible2(false);
        updateEventFormik.resetForm();
      })()
    },
  });

  const SearchField = ({ setFieldValue }) => {
    const map = useMap();
  
    React.useEffect(() => {
      const provider = new OpenStreetMapProvider();
  
      const searchControl = new GeoSearchControl({
        provider,
        showMarker: true,
        showPopup: true,
        marker: {
          draggable: true,
        },
      });
  
      map.addControl(searchControl);
      return () => map.removeControl(searchControl);
    }, [map]);
  
    return null;
  };

  const customFooter = (eventId) => {
    return (
      <div className="flex gap-5 justify-end items-end">
        <Button
          label="No"
          icon="pi pi-times"
          onClick={reject}
          className="btn focus:outline-none focus:ring-0 hover:bg-[#002f499e]"
        />
        {deleteEventLoading ? (
          <i
            className="pi pi-spin pi-spinner text-red-500"
            style={{ fontSize: "2rem" }}
          ></i>
        ) : (
          <Button
            label="Yes"
            icon="pi pi-check"
            onClick={() => accept(eventId)}
            className="bg-red-500 text-white btn hover:bg-red-600 focus:outline-none focus:ring-0"
            autoFocus
          />
        )}
      </div>
    );
  };

  const accept = (eventId) => {
    deleteEvent(eventId);
    deleteEventLoading === false && confirmDialog({ visible: false });
  };
  const reject = () => {
    confirmDialog({ visible: false });
  };

  const confirm2 = (eventId) => {
    confirmDialog({
      message: `Do you want to delete this event?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      footer: customFooter(eventId),
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-end justify-end">
        <Input
          placeholder="Search"
          className="text-sm focus:ring-0 focus:outline-none"
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          prefix={<IoIosSearch />}
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex gap-5">
          <ConfirmDialog footer={{}} />
          <RiImageAddFill
            onClick={() => {
              setVisible2((prev) => !prev);
              setEventId(rowData.id);
            }}
            size={24}
            className=" hover:text-cyan-950 cursor-pointer"
          />
          <MdOutlineDeleteOutline
            onClick={() => confirm2(rowData.id)}
            size={24}
            className="text-red-600 hover:text-red-500 cursor-pointer"
          />
          <FaRegEdit
            onClick={() => {
              setVisibleRight(true);
              setSelectedEvent({
                id: rowData.id,
                title: rowData.title,
                description: rowData.description,
                dates: rowData.date,
                time: rowData.time,
                country: rowData.country,
                state: rowData.state,
                city: rowData.city,
                zip_code: rowData.zip_code,
                coordinates: rowData.coordinates,
                images: rowData.images,
              });
            }}
            className="text-[#003049] hover:text-[#002f499e] cursor-pointer"
            size={24}
          />
        </div>
      </>
    );
  };

  const countBodyTemplate = (rowData) => {
    return events.indexOf(rowData) + 1;
  };
  const imageBodyTemplate = (rowData) => {
    return (
      <div className="relative">
        {rowData.images && rowData.images.length > 0 ? (
          <OverlappingImages images={rowData?.images} />
        ) : (
          <p className="whitespace-nowrap">NO IMAGE</p>
        )}
      </div>
    );
  };

  const header = renderHeader();
  const customHeader = () => (
    <div className="flex items-start justify-start">
      <h1 className="font-semibold">Update event's information</h1>
    </div>
  );


  const discriptionBodyTemplate = (rowData) => {
    const maxLength = 30;
    const description = rowData.description;
    return (
      <span>
        {description.length > maxLength
          ? `${description.substring(0, maxLength)}...`
          : description}
      </span>
    );
  };

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.date);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <div className="flex items-center gap-2 whitespace-nowrap">
        {formattedDate}
      </div>
    );
  };

  const timeBodyTemplate = (rowData) => {
    const time = new Date(rowData.time);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return (
      <div className="flex items-center gap-2 whitespace-nowrap">
        {formattedTime}
      </div>
    );
  };

  useEffect(() => {
    if (selectedEvent.id !== null) {
      updateEventFormik.setValues({
        title: selectedEvent.title,
        description: selectedEvent.description,
        dates: new Date(selectedEvent.dates),
        time: new Date(selectedEvent.time),
        country: selectedEvent.country,
        state: selectedEvent.state,
        city: selectedEvent.city,
        zip_code: selectedEvent.zip_code,
        coordinates: selectedEvent.coordinates,
      });
    }
  }, [selectedEvent]);

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
    exit: (direction) => ({
      x: direction > 0 ? "-100%" : "100%",
      transition: { duration: 0.5 },
    }),
  };

  const showImage = (newIndex, newDirection) => {
    setDirection(newDirection);
    if (newIndex < 0) {
      setCurrentIndex(selectedEvent.images.length - 1);
    } else if (newIndex >= selectedEvent.images.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      showImage(currentIndex + 1, "next");
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex, selectedEvent.images.length]);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="pt-5 px-4">
        <div className="flex justify-between">
          <h1 className="text-[24px] text-[#003049] font-semibold">
            Calendar
          </h1>
          <div>
            <Button
              label="Add event"
              className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
              onClick={() => setVisible(true)}
              icon="pi pi-plus"
            />
          </div>
        </div>
        <div className="bg-white rounded-md mt-5 text-[18px]">
          <div className="card">
            <DataTable
              value={events}
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem", fontSize: "14px" }}
              header={header}
              globalFilter={filters.global.value}
              emptyText="No event found."
              filters={filters}
              rowClassName={(index) => {
                return {
                  "bg-gray-100": index + (1 % 2) === 0,
                };
              }}
            >
              <Column
                header="#"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "10%" }}
                body={countBodyTemplate}
              ></Column>
              <Column
                field="title"
                header="Title"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="imges"
                header="Images"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "50rem" }}
                body={imageBodyTemplate}
              ></Column>
              <Column
                field="country"
                header="Country"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="state"
                header="State"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="city"
                header="City"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="description"
                header="Description"
                headerClassName="bg-[#003049] text-white"
                className="whitespace-nowrap"
                style={{ width: "25%" }}
                body={discriptionBodyTemplate}
              ></Column>
              <Column
                field="time"
                header="Time"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={timeBodyTemplate}
              ></Column>
              <Column
                field="date"
                header="date"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={dateBodyTemplate}
              ></Column>
              <Column
                field="zip_code"
                header="Zip Code"
                headerClassName="bg-[#003049] text-white whitespace-nowrap"
                style={{ width: "25%" }}
              ></Column>
              <Column
                header="Action"
                headerClassName="bg-[#003049] text-white"
                style={{ width: "25%" }}
                body={actionBodyTemplate}
              ></Column>
            </DataTable>
            <Sidebar
              visible={visibleRight}
              position="right"
              onHide={() => setVisibleRight(false)}
              style={{ width: "30rem" }}
              header={customHeader}
            >
              <div className="mt-5">
                <form onSubmit={updateEventFormik.handleSubmit}>
                  <div className="relative w-full h-80 sm:h-80 md:h-80 lg:h-[20rem] mb-5 overflow-hidden">
                    <AnimatePresence initial={false} custom={direction}>
                      <motion.div
                        key={currentIndex}
                        className="absolute w-full h-full"
                        variants={slideVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        custom={direction}
                      >
                        <img
                          src={selectedEvent.images[currentIndex]?.image_url}
                          alt={`Event ${currentIndex + 1}`}
                          className="w-full h-full object-cover"
                        />

                        {/* Overlay Text */}
                        <div className="absolute bottom-0 w-full bg-gradient-to-t from-black/60 to-transparent text-white p-4">
                          <h2 className="text-xl font-semibold">
                            {selectedEvent?.title}
                          </h2>
                          <p className="text-sm">
                            {selectedEvent?.description}
                          </p>
                          <div className="flex items-center justify-between">
                            <p className="text-sm">{selectedEvent?.dates}</p>
                            <p className="text-sm">{selectedEvent?.time}</p>
                          </div>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="title"
                        name="title"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 "
                        onChange={updateEventFormik.handleChange}
                        onBlur={updateEventFormik.handleBlur}
                        value={updateEventFormik.values.title}
                      />
                      <label htmlFor="title" className="text-sm">
                        Title
                      </label>
                    </FloatLabel>
                    {updateEventFormik.touched.title &&
                      updateEventFormik.errors.title && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.title}
                        </div>
                      )}
                  </div>
                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="description"
                        name="description"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateEventFormik.handleChange}
                        onBlur={updateEventFormik.handleBlur}
                        value={updateEventFormik.values.description}
                      />
                      <label htmlFor="description" className="text-sm">
                        Description
                      </label>
                    </FloatLabel>
                    {updateEventFormik.touched.description &&
                      updateEventFormik.errors.description && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.description}
                        </div>
                      )}
                  </div>
                  <div className="grid grid-flow-col grid-cols-2 sm:gap-2">
                    <div>
                      <Calendar
                        style={{
                          width: "100%",
                        }}
                        id="dates"
                        name="dates"
                        value={updateEventFormik.values.dates}
                        onChange={(e) =>
                          updateEventFormik.setFieldValue("dates", e.value)
                        }
                        onBlur={updateEventFormik.handleBlur}
                        placeholder="Date"
                      />

                      {updateEventFormik.touched.dates &&
                        updateEventFormik.errors.dates && (
                          <div
                            className="text-[#BA1500] text-sm"
                            aria-live="polite"
                          >
                            {updateEventFormik.errors.dates}
                          </div>
                        )}
                    </div>
                    <div>
                      <Calendar
                        style={{
                          width: "100%",
                        }}
                        id="time"
                        name="time"
                        value={updateEventFormik.values.time}
                        onBlur={updateEventFormik.handleBlur}
                        onChange={(e) =>
                          updateEventFormik.setFieldValue("time", e.value)
                        }
                        timeOnly
                        placeholder="Time"
                      />

                      {updateEventFormik.touched.time &&
                        updateEventFormik.errors.time && (
                          <div
                            className="text-[#BA1500] text-sm"
                            aria-live="polite"
                          >
                            {updateEventFormik.errors.time}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="country"
                        name="country"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateEventFormik.handleChange}
                        onBlur={updateEventFormik.handleBlur}
                        value={updateEventFormik.values.country}
                      />
                      <label htmlFor="country" className="text-sm">
                        Country
                      </label>
                    </FloatLabel>
                    {updateEventFormik.touched.country &&
                      updateEventFormik.errors.country && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.country}
                        </div>
                      )}
                  </div>
                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="state"
                        name="state"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateEventFormik.handleChange}
                        onBlur={updateEventFormik.handleBlur}
                        value={updateEventFormik.values.state}
                      />
                      <label htmlFor="state" className="text-sm">
                        State
                      </label>
                    </FloatLabel>
                    {updateEventFormik.touched.state &&
                      updateEventFormik.errors.state && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.state}
                        </div>
                      )}
                  </div>
                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="city"
                        name="city"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateEventFormik.handleChange}
                        onBlur={updateEventFormik.handleBlur}
                        value={updateEventFormik.values.city}
                      />
                      <label htmlFor="city" className="text-sm">
                        City
                      </label>
                    </FloatLabel>
                    {updateEventFormik.touched.city &&
                      updateEventFormik.errors.city && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.city}
                        </div>
                      )}
                  </div>

                  <div className="py-2 my-2">
                    <FloatLabel>
                      <InputText
                        id="zip_code"
                        name="zip_code"
                        type="text"
                        className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                        onChange={updateEventFormik.handleChange}
                        onBlur={updateEventFormik.handleBlur}
                        value={updateEventFormik.values.zip_code}
                      />
                      <label htmlFor="zip_code" className="text-sm">
                        Zip code
                      </label>
                    </FloatLabel>
                    {updateEventFormik.touched.zip_code &&
                      updateEventFormik.errors.zip_code && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.zip_code}
                        </div>
                      )}
                  </div>
                  <div>
                    <Accordion>
                      <AccordionTab header="Choose location on map">
                        <MapContainer
                          center={[0, 0]}
                          zoom={2}
                          style={{ height: "300px", width: "100%" }}
                        >
                          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                          <LocationPicker
                            setFieldValue={updateEventFormik.setFieldValue}
                            value={updateEventFormik.values.coordinates}
                          />
                        </MapContainer>
                      </AccordionTab>
                    </Accordion>
                    {updateEventFormik.touched.coordinates &&
                      updateEventFormik.errors.coordinates && (
                        <div
                          className="text-[#BA1500] text-sm"
                          aria-live="polite"
                        >
                          {updateEventFormik.errors.coordinates}
                        </div>
                      )}
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                    disabled={updateEventLoading}
                  >
                    {updateEventLoading ? (
                      <i
                        className="pi pi-spin pi-spinner text-white"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </Sidebar>
            <Dialog
              header="Add Events"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => {
                setVisible(false);
                eventFormik.resetForm();
              }}
            >
              <form onSubmit={eventFormik.handleSubmit}>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="title"
                      name="title"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 "
                      onChange={eventFormik.handleChange}
                      onBlur={eventFormik.handleBlur}
                      value={eventFormik.values.title}
                    />
                    <label htmlFor="title" className="text-sm">
                      Title
                    </label>
                  </FloatLabel>
                  {eventFormik.touched.title && eventFormik.errors.title && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {eventFormik.errors.title}
                    </div>
                  )}
                </div>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="description"
                      name="description"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={eventFormik.handleChange}
                      onBlur={eventFormik.handleBlur}
                      value={eventFormik.values.description}
                    />
                    <label htmlFor="description" className="text-sm">
                      Description
                    </label>
                  </FloatLabel>
                  {eventFormik.touched.description &&
                    eventFormik.errors.description && (
                      <div
                        className="text-[#BA1500] text-sm"
                        aria-live="polite"
                      >
                        {eventFormik.errors.description}
                      </div>
                    )}
                </div>
                <div className="grid grid-flow-col grid-cols-2 sm:gap-2">
                  <div>
                    <Calendar
                      style={{
                        width: "100%",
                      }}
                      id="dates"
                      name="dates"
                      value={eventFormik.values.dates}
                      onChange={(e) =>
                        eventFormik.setFieldValue("dates", e.value)
                      }
                      onBlur={eventFormik.handleBlur}
                      placeholder="Date"
                    />

                    {eventFormik.touched.dates && eventFormik.errors.dates && (
                      <div
                        className="text-[#BA1500] text-sm"
                        aria-live="polite"
                      >
                        {eventFormik.errors.dates}
                      </div>
                    )}
                  </div>
                  <div>
                    <Calendar
                      style={{
                        width: "100%",
                      }}
                      id="time"
                      name="time"
                      value={eventFormik.values.time}
                      onBlur={eventFormik.handleBlur}
                      onChange={(e) =>
                        eventFormik.setFieldValue("time", e.value)
                      }
                      timeOnly
                      hourFormat="12"
                      placeholder="Time"
                    />

                    {eventFormik.touched.time && eventFormik.errors.time && (
                      <div
                        className="text-[#BA1500] text-sm"
                        aria-live="polite"
                      >
                        {eventFormik.errors.time}
                      </div>
                    )}
                  </div>
                </div>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="state"
                      name="state"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={eventFormik.handleChange}
                      onBlur={eventFormik.handleBlur}
                      value={eventFormik.values.state}
                    />
                    <label htmlFor="state" className="text-sm">
                      State
                    </label>
                  </FloatLabel>
                  {eventFormik.touched.state && eventFormik.errors.state && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {eventFormik.errors.state}
                    </div>
                  )}
                </div>
                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="city"
                      name="city"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={eventFormik.handleChange}
                      onBlur={eventFormik.handleBlur}
                      value={eventFormik.values.city}
                    />
                    <label htmlFor="city" className="text-sm">
                      City
                    </label>
                  </FloatLabel>
                  {eventFormik.touched.city && eventFormik.errors.city && (
                    <div className="text-[#BA1500] text-sm" aria-live="polite">
                      {eventFormik.errors.city}
                    </div>
                  )}
                </div>

                <div className="py-2 my-2">
                  <FloatLabel>
                    <InputText
                      id="zip_code"
                      name="zip_code"
                      type="text"
                      className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"
                      onChange={eventFormik.handleChange}
                      onBlur={eventFormik.handleBlur}
                      value={eventFormik.values.zip_code}
                    />
                    <label htmlFor="zip_code" className="text-sm">
                      Zip code
                    </label>
                  </FloatLabel>
                  {eventFormik.touched.zip_code &&
                    eventFormik.errors.zip_code && (
                      <div
                        className="text-[#BA1500] text-sm"
                        aria-live="polite"
                      >
                        {eventFormik.errors.zip_code}
                      </div>
                    )}
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50"
                  disabled={createEventLoading}
                >
                  {createEventLoading ? (
                    <i
                      className="pi pi-spin pi-spinner text-white"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            </Dialog>
            <FileUploadDialog
              visible={visible2}
              onClose={() => setVisible2(false)}
              selectedEvent={eventId}
              addEventImages={addEventImages}
              addEventImagesLoading={addEventImagesLoading}
              addEventImagesSuccess={addEventImagesSuccess}
              addEventImagesReset={addEventImagesReset}
            />
          </div>
        </div>
      </div>
    </>
  );
};
