import React, { useState } from 'react';
import PdfViewer from '../components/Pdf/PdfViewer';
import { useFloorPlan } from '../hooks/useFloorPlan';
import { Empty } from 'antd';
import { Accordion, AccordionTab } from 'primereact/accordion';

export const FloorPlan = () => {
    const { FloorPlan, isLoading, error } = useFloorPlan();
    const [activeIndex, setActiveIndex] = useState(null);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const getFileExtension = (url) => {
        if (typeof url !== 'string') return null; 
        const cleanUrl = url.split('?')[0];
        return cleanUrl.split('.').pop().toLowerCase();
    };

    return (
        <div className="card">
            {FloorPlan && FloorPlan.length > 0 ? (
                <Accordion 
                    activeIndex={activeIndex} 
                    onTabChange={(e) => setActiveIndex(e.index)}
                    className="custom-tabview"
                >
                    {FloorPlan.map((doc, index) => (
                        <AccordionTab 
                            header={doc.title} 
                            key={index} 
                            headerClassName={`text-[#003049] uppercase ${activeIndex === index ? "active-tab" : ""}`}
                        >
                            {Array.isArray(doc.floorUrls) ? (
                                doc.floorUrls.map((fileUrl, idx) => {
                                    const fileExtension = getFileExtension(fileUrl.url);

                                    return (
                                        <div key={idx} className="mb-4">
                                            {fileExtension === 'pdf' ? (
                                                <PdfViewer pdfUrl={fileUrl.url} />
                                            ) : ['jpeg', 'jpg', 'png', 'gif', 'webp'].includes(fileExtension) ? (
                                                <img src={fileUrl.url} alt={`Floor plan ${idx + 1}`} className="w-full" />
                                            ) : (
                                                <div>Unsupported file type</div>
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                <div><Empty /></div>
                            )}
                        </AccordionTab>
                    ))}
                </Accordion>
            ) : (
                <div className="w-full min-h-screen flex justify-center items-center">
                    <Empty />
                </div>
            )}
        </div>
    );
};
