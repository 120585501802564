import { toast } from "react-hot-toast";
import { sendEmail } from "../api/contactApi";
import { useMutation } from "@tanstack/react-query";

export const useEmail = () => {
  const sendEmailMutation = useMutation({
    mutationFn: sendEmail,
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to send email");
    },
  });
  return {
    sendEmailContact: sendEmailMutation.mutate,
    sendEmailLoading: sendEmailMutation.isPending,
    sendEmailSuccess: sendEmailMutation.isSuccess,
    sendEmailError: sendEmailMutation.error,
  };
};
