import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../utils/axios";
import { Button, Modal, Input, Form, message, Upload } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";

const CommunityManagePage = () => {
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [createForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [createFileList, setCreateFileList] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);

  useEffect(() => {
    fetchCommunity();
  }, []);

  const fetchCommunity = async () => {
    try {
      const response = await axiosInstance.get("/api/community");
      setCommunity(response.data);
      form.setFieldsValue(response.data); // Pre-fill form for editing
    } catch (error) {
      message.error("Failed to fetch community data");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCommunity = async (values) => {
    if (!community?.id) {
        message.error("Community ID is missing!");
        return;
    }

    const formData = new FormData();
    formData.append("id", community.id); // ✅ Ensure ID is sent

    // ✅ Fix: Only use `split(",")` if `amenities` is a string
    if (values.amenities) {
        if (Array.isArray(values.amenities)) {
            formData.append("amenities", JSON.stringify(values.amenities)); // Already an array
        } else {
            formData.append("amenities", JSON.stringify(values.amenities.split(",").map(item => item.trim())));
        }
    }

    Object.keys(values).forEach((key) => {
        if (key !== "amenities") {
            formData.append(key, values[key]);
        }
    });

    if (fileList.length > 0) {
        formData.append("image", fileList[0].originFileObj);
    }

    try {
        await axiosInstance.put("/api/community", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        message.success("Community updated successfully");
        setIsModalOpen(false);
        fetchCommunity();
    } catch (error) {
        message.error("Failed to update community");
    }
};


  const handleCreateCommunity = async (values) => {
    const formData = new FormData();
    if (values.amenities) {
      formData.append("amenities", JSON.stringify(values.amenities.split(",")));
    }
    Object.keys(values).forEach((key) => {
      if (key !== "amenities") {
        formData.append(key, values[key]);
      }
    });

    if (createFileList.length > 0) {
      formData.append("image", createFileList[0].originFileObj);
    }

    try {
      await axiosInstance.post("/api/community", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Community created successfully");
      setIsCreateModalOpen(false);
      setCreateFileList([]);
      createForm.resetFields();
      fetchCommunity();
    } catch (error) {
      message.error("Failed to create community");
    }
  };

  const handleAddImage = async () => {
    if (uploadFileList.length === 0) {
      message.warning("Please select an image to upload");
      return;
    }

    const formData = new FormData();
    formData.append("communityId", community.id);
    formData.append("image", uploadFileList[0].originFileObj);

    try {
      await axiosInstance.post("/api/community/add-image", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Image added successfully");
      setIsUploadModalOpen(false);
      setUploadFileList([]);
      fetchCommunity();
    } catch (error) {
      message.error("Failed to upload image");
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      await axiosInstance.delete(`/api/community/delete-image/${imageId}`);
      message.success("Image deleted successfully");
      fetchCommunity();
    } catch (error) {
      message.error("Failed to delete image");
    }
  };

  return (
    <div className="p-5">
      <h1 className="text-2xl font-semibold text-[#003049] mb-5">Community Page Management</h1>

      <div className="flex justify-between">
        {/* <Button type="primary" className="bg-[#BA1500] text-white" onClick={() => setIsCreateModalOpen(true)}>
          Add Community
        </Button> */}
        <Button type="primary" className="bg-[#BA1500] text-white" onClick={() => setIsModalOpen(true)}>
          Edit
        </Button>
        
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : community ? (
        <div className="bg-white p-4 shadow-md mt-2">
          <p className="text-[#003049]"><strong>Name:</strong> {community.name}</p>
          <p className="text-[#003049]"><strong>Established Year:</strong> {community.establishedYear}</p>
          <p className="text-[#003049]"><strong>Total Homes:</strong> {community.totalHomes}</p>
          <p className="text-[#003049]"><strong>Description:</strong> {community.description}</p>
          <p className="text-[#003049]"><strong>Amenities:</strong> {community.amenities?.join(", ")}</p>

          {/* Images Section */}
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Images</h2>
            <div className="flex gap-4 flex-wrap">
              {community.images?.map((image) => (
                <div key={image.id} className="relative">
                  <img src={image.imageUrl} alt="Community" className="w-32 h-32 object-cover rounded" />
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="absolute top-1 right-1"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteImage(image.id)}
                  />
                </div>
              ))}
            </div>
          </div>

          <Button type="primary" className="mt-4 bg-red-700" onClick={() => setIsUploadModalOpen(true)}>
            Upload Image
          </Button>
        </div>
      ) : (
        <p>No community data available.</p>
      )}

      {/* Edit Community Modal */}
      <Modal title="Edit Community" open={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={() => form.submit()}>
        <Form form={form} onFinish={handleUpdateCommunity} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter name" }]}> 
            <Input className="border border-gray-500 p-2 rounded-md" />
          </Form.Item>
          <Form.Item name="establishedYear" label="Established Year" rules={[{ required: true, message: "Please enter year" }]}> 
            <Input type="number" className="border border-gray-500 p-2 rounded-md" />
          </Form.Item>
          <Form.Item name="totalHomes" label="Total Homes" rules={[{ required: true, message: "Please enter total homes" }]}> 
            <Input type="number" className="border border-gray-500 p-2 rounded-md" />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please enter description" }]}> 
            <Input.TextArea className="border border-gray-500 p-2 rounded-md" />
          </Form.Item>
          <Form.Item name="amenities" label="Amenities" tooltip="Enter comma-separated values (e.g., Pool, Gym)">
      <Input placeholder="Enter amenities as comma-separated values" className="border border-gray-500 p-2 rounded-md" />
    </Form.Item>
         
        </Form>
      </Modal>

      <Modal
  title="Add Community"
  open={isCreateModalOpen}
  onCancel={() => {
    setIsCreateModalOpen(false);
    createForm.resetFields(); // Reset fields when closing modal
    setCreateFileList([]); // Clear file selection
  }}
  onOk={() => createForm.submit()}
>
  <Form form={createForm} onFinish={handleCreateCommunity} layout="vertical">
    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter name" }]}>
      <Input className="border border-gray-500 p-2 rounded-md" />
    </Form.Item>

    <Form.Item name="establishedYear" label="Established Year" rules={[{ required: true, message: "Please enter year" }]}>
      <Input type="number" className="border border-gray-500 p-2 rounded-md" />
    </Form.Item>

    <Form.Item name="totalHomes" label="Total Homes" rules={[{ required: true, message: "Please enter total homes" }]}>
      <Input type="number" className="border border-gray-500 p-2 rounded-md" />
    </Form.Item>

    <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please enter description" }]}>
      <Input.TextArea className="border border-gray-500 p-2 rounded-md" />
    </Form.Item>

    <Form.Item name="amenities" label="Amenities" tooltip="Enter comma-separated values (e.g., Pool, Gym)">
      <Input placeholder="Enter amenities as comma-separated values" className="border border-gray-500 p-2 rounded-md" />
    </Form.Item>

    <Form.Item label="Upload Image">
      <Upload
        fileList={createFileList}
        beforeUpload={() => false} // Prevents automatic upload
        onChange={({ fileList }) => setCreateFileList(fileList)} // Update state
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Select Image</Button>
      </Upload>
    </Form.Item>
  </Form>
</Modal>


{/* Upload Image Modal */}
<Modal title="Upload Community Image" open={isUploadModalOpen} onCancel={() => setIsUploadModalOpen(false)} onOk={handleAddImage}>
        <Upload fileList={uploadFileList} beforeUpload={() => false} onChange={({ fileList }) => setUploadFileList(fileList)} maxCount={1}>
          <Button icon={<UploadOutlined />}>Select Image</Button>
        </Upload>
      </Modal>

    </div>
  );
};

export default CommunityManagePage;
