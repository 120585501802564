import { useEffect, useState } from "react";
import Logo from "./assets/logo.png";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Login } from "../components/Login";
import { IoMdArrowDropdown } from "react-icons/io";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeNav, setActiveNav] = useState("home");
  const [activeDropdown, setActiveDropdown] = useState(null);

  const pathname = window.location.pathname
  const handleNavClick = (navItem) => {
    setActiveNav(navItem);
    setIsMenuOpen(false);
  };

  const toggleDropdown = (menu) => {
    setActiveDropdown((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  useEffect(()=>{
    setActiveNav(pathname === "/"? "home" : pathname.split("/")[1])
  },[pathname])

  return (
    <>
      <header className="bg-[#BA1500] text-white sticky">
        <div className="container mx-auto flex justify-between items-center px-4 py-3">
          <Link
            to="/"
            className="flex items-center"
            onClick={() => handleNavClick("/")}
          >
            <img src={Logo} alt="Logo" className="h-16 w-16 mr-4" />
            <div>
              <h1 className="md:text-3xl font-bold uppercase">About Yarcobr</h1>
              <p className="text-sm italic">A Jewel on A1A, Boca Raton</p>
            </div>
          </Link>

          <div className="hidden lg:block text-right">
            <p className="text-lg font-semibold">+1 561-702-3795</p>
            <p className="text-sm">Info2025@AboutYarcobr.com</p>
          </div>

          <button
            className="lg:hidden block text-2xl"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            ☰
          </button>
        </div>

        <nav
          className={`bg-[#d00d0e] transition-all duration-300 lg:flex lg:items-center lg:justify-center font-bold ${
            isMenuOpen ? "block" : "hidden"
          }`}
        >
          <ul className="flex flex-col lg:flex-row lg:gap-6 text-sm py-2 lg:py-2 uppercase font-bold">
            <li>
              <Link
                to="/"
                className={`hover:bg-[#BA1500] px-4 py-2 block ${
                  activeNav === "home" ? "bg-[#BA1500]" : ""
                }`}
                onClick={() => handleNavClick("home")}
              >
                HOME
              </Link>
            </li>
            <li
              className="relative"
              onMouseLeave={handleMouseLeave}
            >
              <div className={`hover:bg-[#BA1500] px-4 py-1 flex justify-between lg:justify-center items-center ${
                  activeNav === "community" ? "bg-[#BA1500]" : ""
                }`}>
              <Link
                to="/community"
              >
                <span>COMMUNITY</span>
              </Link>
              <IoMdArrowDropdown size={32}  onClick={() => toggleDropdown("community")} className="cursor-pointer"/>
              </div>
              
              {activeDropdown === "community" && (
                <ul
                  // onMouseEnter={() => handleMouseEnter("community")}
                  // onMouseLeave={handleMouseLeave}
                  className="absolute z-50 left-0 w-full md:w-48 bg-white text-black shadow-md rounded-md capitalize font-bold"
                >
                  <li>
                    <Link
                      to="/events"
                      className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-4 py-2 block ${
                        activeNav === "events"
                          ? "bg-[#BA1500] hover:text-white"
                          : ""
                      }`}
                      onClick={() => handleNavClick("events")}
                    >
                      calendar
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Board-members"
                      className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-4 py-2 block  ${
                        activeNav === "members"
                          ? "bg-[#BA1500] text-white"
                          : ""
                      }`}
                      onClick={() => handleNavClick("members")}
                    >
                      Members
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-4 py-2 block  ${
                        activeNav === "news"
                          ? "bg-[#BA1500] text-white"
                          : ""
                      }`}
                      onClick={() => handleNavClick("news")}
                    >
                      news
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Floor-Plans"
                      className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-4 py-2 block  ${
                        activeNav === "floor-plans"
                          ? "bg-[#BA1500] text-white"
                          : ""
                      }`}
                      onClick={() => handleNavClick("floor-plans")}
                    >
                      floor plans
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Rules-And-Regulations"
                      className={`hover:bg-[#BA1500] hover:text-white text-[#003049] px-4 py-2 block  ${
                        activeNav === "docs"
                          ? "bg-[#BA1500] text-white"
                          : ""
                      }`}
                      onClick={() => handleNavClick("docs")}
                    >
                      rules & regulations
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <Link
                to="/ARN-Team"
                className={`hover:bg-[#BA1500] px-4 py-2 block ${
                  activeNav === "team" ? "bg-[#BA1500]" : ""
                }`}
                onClick={() => handleNavClick("team")}
              >
              TEAM
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                className={`hover:bg-[#BA1500] px-4 py-2 block ${
                  activeNav === "contact" ? "bg-[#BA1500]" : ""
                }`}
                onClick={() => handleNavClick("contact")}
              >
                CONTACT US
              </Link>
            </li>

            <li>
              <Link
                to="/mapsearch"
                className={`hover:bg-[#BA1500] px-4 py-2 block ${
                  activeNav === "mapsearch" ? "bg-[#BA1500]" : ""
                }`}
                onClick={() => handleNavClick("mapsearch")}
              >
                MAP SEARCH
              </Link>
            </li>
            <li className="lg:ml-1">
              <Button
                style={{ border: "none" }}
                label="Sign In"
                className="hover:bg-[#BA1500] border rounded-none px-4 py-2 uppercase focus:outline-none focus:ring-1 focus:ring-gray-100 block"
                onClick={(e) => {
                  e.preventDefault();
                  setVisible(true);
                  setIsMenuOpen(false);
                }}
              />
            </li>
          </ul>
        </nav>
      </header>
      {visible && <Login visible={visible} onClose={() => setVisible(false)} />}
    </>
  );
};

export default Header;
