import React, { useState } from "react";
import { Icon } from "@iconify-icon/react";
import { motion, AnimatePresence } from "framer-motion";
import { EventModal } from "../components/eventModal";
import { useEvents } from "../hooks/useEvent";
import { convertDate, convertTime } from "../helper";
import { Empty } from "antd";

export const Events = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [direction, setDirection] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [visibleCount, setVisibleCount] = useState(6); // Show 6 initially
  const { events } = useEvents();

  const showMore = () => {
    setVisibleCount((prev) => prev + 4); // Load 4 more
  };

  const slideVariants = {
    enter: (direction) => ({ x: direction > 0 ? "100%" : "-100%" }),
    center: { x: 0, opacity: 1, transition: { duration: 0.5 } },
    exit: (direction) => ({ x: direction > 0 ? "-100%" : "100%", opacity: 0, transition: { duration: 0.5 } }),
  };

  const showImage = (newIndex, newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prev) => (newIndex < 0 ? events.length - 1 : newIndex >= events.length ? 0 : newIndex));
  };

  const showEventDetails = (event) => {
    setSelectedEvent(event);
    setVisible(true);
  };

  return !events || events.length === 0 ? (
    <div className="w-full min-h-screen flex justify-center items-center"><Empty /></div>
  ) : (
    <div>
      {/* Event Image Slider */}
      <div className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[40rem] overflow-hidden">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentIndex}
            className="absolute w-full h-full"
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
          >
            <img
              src={events[currentIndex]?.images[0]?.image_url}
              alt={`Event ${currentIndex + 1}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 w-full bg-gradient-to-t from-black/60 to-transparent text-white p-4">
              <h2 className="text-xl font-semibold">{events[currentIndex]?.title}</h2>
              <p className="text-sm">{events[currentIndex]?.description}</p>
              <div className="flex items-center justify-between">
                <p className="text-sm">{convertDate(events[currentIndex]?.date)}</p>
                <p className="text-sm">{convertTime(events[currentIndex]?.time)}</p>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>

        {/* Navigation Buttons */}
        <div className="flex items-center justify-between absolute top-1/2 transform -translate-y-1/2 w-full px-5">
          <button onClick={() => showImage(currentIndex - 1, -1)} className="px-3 py-1 bg-black/40 hover:bg-black/70 text-white transition rounded-full">
            <Icon icon="ep:arrow-left-bold" width="20" height="40" />
          </button>
          <button onClick={() => showImage(currentIndex + 1, 1)} className="px-3 py-1 bg-black/40 hover:bg-black/70 text-white transition rounded-full">
            <Icon icon="weui:arrow-filled" width="20" height="40" />
          </button>
        </div>
      </div>

      {/* Events List */}
      <div className="container mx-auto px-4 py-6 flex flex-col items-center">
        <h1 className="text-4xl font-bold text-[#BA1500]">Upcoming Events</h1>
        <p className="mt-4 text-lg">In the community and in the area</p>
        
        <div className="grid md:grid-cols-2 gap-6 md:p-6">
          {events.slice(0, visibleCount).map((event, index) => (
            <motion.div
              key={event.id}
              className="flex gap-4 p-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <img src={event.images[0]?.image_url} alt={event.title} className="w-40 h-56 rounded-lg object-cover" />
              <div>
                <h2 className="text-base sm:text-[8px] md:text-sm font-semibold">{event.title}</h2>
                <p className="text-gray-500 mt-2 text-sm">📅 {convertDate(event.date)} at {convertTime(event.time)} on <strong>{event.state}, {event.city}, {event.country}</strong></p>
                <p className="text-gray-400 text-sm mt-2">{event.description}</p>
                <button onClick={() => showEventDetails(event)} className="mt-4 border px-4 py-1 rounded text-gray-500 hover:bg-red-600 hover:text-white">
                  CLICK HERE FOR MORE DETAILS
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {/* View More Button */}
        {visibleCount < events.length && (
          <motion.button
            onClick={showMore}
            className="mt-6 bg-red-600 text-white px-12 py-4 rounded-md hover:bg-red-700 transition"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            View More
          </motion.button>
        )}
      </div>

      {/* Event Modal */}
      {visible && <EventModal visible={visible} onClose={() => setVisible(false)} data={selectedEvent} />}
    </div>
  );
};
