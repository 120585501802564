import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export const Layout = () => {
  const location = useLocation();

  // Check if the current route is "/events"
  const isEventsPage = location.pathname === "/events";

  return (
    <div className="App flex flex-col min-h-screen">
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <main className={isEventsPage ? "" : "flex-grow container mx-auto px-4 py-6"}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
