import React, {useState} from 'react'
import PdfViewer from '../components/Pdf/PdfViewer';
import {useDocs} from '../hooks/useDoc'
import { Empty } from 'antd';
import { Accordion, AccordionTab } from 'primereact/accordion';

export const Doc = () => {
    const { docs, isLoading, error } = useDocs();
    const [activeIndex, setActiveIndex] = useState(0);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="card">
            {docs && docs?.length > 0 ?<Accordion 
                activeIndex={activeIndex} 
                onTabChange={(e) => setActiveIndex(e.index)}
                className="custom-tabview"
            >
                {docs &&
                    docs.map((doc, index) => (
                        <AccordionTab 
                            header={doc.title} 
                            key={index} 
                            headerClassName={`text-[#003049] uppercase ${activeIndex === index ? "active-tab" : ""}`}
                        >
                            <PdfViewer pdfUrl={doc.url} />
                        </AccordionTab>
                    ))}
            </Accordion> : 
            <div className="w-full min-h-screen flex justify-center items-center"><Empty /></div>
            }
        </div>
    );
};
   