import React, { useEffect, useState, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { FileUpload } from "primereact/fileupload";
import toast from "react-hot-toast";
import { Editor } from "@tinymce/tinymce-react";
import axiosInstance from "../utils/axios";
import DOMPurify from "dompurify";

const ManageBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [labels, setLabels] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [labelName, setLabelName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ title: "", content: "", category_id: null, label_ids: [], images: [] });
  const [visible, setVisible] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    Promise.all([
      axiosInstance.get("/api/blogs"),
      axiosInstance.get("/api/blogs/categories/categories"),
      axiosInstance.get("/api/blogs/labels/labels")
    ])
      .then(([blogsRes, categoriesRes, labelsRes]) => {
        setBlogs(blogsRes.data);
        setCategories(categoriesRes.data);
        setLabels(labelsRes.data);
      })
      .catch(() => setError("Failed to fetch data"))
      .finally(() => setLoading(false));
  }, []);

  const deleteBlog = async (id) => {
    try {
      await axiosInstance.delete(`/api/blogs/${id}`);
      setBlogs(blogs.filter(blog => blog.id !== id));
      toast.success("Blog deleted successfully");
    } catch {
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete blog", life: 3000 });
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (event) => {
    setFormData({ ...formData, images: event.files });
  };

 


  const handleEditorChange = (content) => {
    setFormData({ ...formData, content });
  };

  const saveBlog = async () => {
    if (!formData.title || !formData.content) {
      toast.success("Title and content are required!");
      return;
    }

    const form = new FormData();
    form.append("title", formData.title);
    form.append("content", formData.content);
    form.append("category_id", formData.category_id);
    formData.label_ids.forEach(label => form.append("label_ids", label));
    formData.images.forEach(file => form.append("images", file));

    try {
      const response = editingId
        ? await axiosInstance.put(`/api/blogs/${editingId}`, form)
        : await axiosInstance.post("/api/blogs", form);

      setBlogs(editingId ? blogs.map(blog => (blog.id === editingId ? response.data : blog)) : [...blogs, response.data]);
      setEditingId(null);
      setVisible(false);
      
      toast.success("Blog saved successfully");
    } catch (error) {
      toast.error("Failed to save blog");
    }
  };

  const saveCategory = async () => {
    try {
      await axiosInstance.post("/api/blogs/categories", { name: categoryName });
      setCategoryName("");
      toast.success( "Category added");
    } catch {
      toast.error( "Failed to add category");
    }
  };

  const saveLabel = async () => {
    try {
      await axiosInstance.post("/api/blogs/labels", { name: labelName });
      setLabelName("");
      toast.success.show("Label added");
    } catch {
      toast.error.show("Failed to add label");
    }
  };

  const truncateContent = (content) => {
    const cleanContent = DOMPurify.sanitize(content, { ALLOWED_TAGS: [] });
    return cleanContent.length > 100 ? cleanContent.substring(0, 100) + "..." : cleanContent;
  };

  return (
    <div className="p-5">
       <div className="flex justify-between">
              <h1 className="text-2xl font-semibold text-[#003049] mb-5">
                News Management
              </h1>
              <div>
                <Button
                  label="Add blog"
                  icon="pi pi-plus"
                  className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0"
                  onClick={() => setVisible(true)}
                />
              </div>
            </div>
      <TabView>
        <TabPanel header="News" headerClassName="text-[#003049]">
        <DataTable value={blogs} paginator rows={5} responsiveLayout="scroll">
        <Column field="title" header="Title" headerClassName="bg-[#003049] text-white"  sortable></Column>
        <Column field="content" header="Content" headerClassName="bg-[#003049] text-white"  body={(rowData) => truncateContent(rowData.content)} sortable></Column>
        <Column field="category.name" header="Category" headerClassName="bg-[#003049] text-white" sortable></Column>
        <Column field="labels" header="Labels" headerClassName="bg-[#003049] text-white" body={(rowData) => rowData.labels.map(label => label.label.name).join(", ")}></Column>
            <Column header="Actions"  headerClassName="bg-[#003049] text-white" body={(rowData) => (
              <div className="flex">
                <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => {
                  setEditingId(rowData.id);
                  setFormData(rowData);
                  setVisible(true);
                }} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => deleteBlog(rowData.id)} />
              </div>
            )}></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Categories & Labels" headerClassName="text-[#003049]">
          <div className="mx-auto">
            <div className="field">
              <label>New Category</label>
              <InputText value={categoryName} className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 mb-2" onChange={(e) => setCategoryName(e.target.value)} />
              <Button label="Add Category" className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0" onClick={() => saveCategory()} />
            </div>
            <div className="field">
              <label>New Label</label>
              <InputText value={labelName} className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100 mb-2" onChange={(e) => setLabelName(e.target.value)} />
              <Button label="Add Label" className="bg-[#BA1500] text-white btn text-sm focus:outline-none focus:ring-0" onClick={() => saveLabel()} />
            </div>
          </div>
        </TabPanel>
      </TabView>
      <Dialog header={editingId ? "Edit Blog" : "Add Blog"} visible={visible} style={{ width: "30vw" }} onHide={() => setVisible(false)}>
        <div className="mx-auto">
          <div className="field">
            <label>Title</label>
            <InputText name="title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"/>
          </div>
          <div className="field">
            <label>Content</label>

<Editor
      apiKey='imp30wmakgxggg3lt4rjnh018w9lkubsrag2j14c91eacaf1'
      initialValue={formData.content}
      init={{
        height: 300,
        plugins: [
          'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
         
          'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown','importword', 'exportword', 'exportpdf'
        ],
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
        image_uploadtab: true,
              }}
              onEditorChange={handleEditorChange}
     
    />

          </div>
          <div className="field">
            <label>Category</label>
            <Dropdown name="category_id" value={formData.category_id} options={categories} optionLabel="name" optionValue="id" onChange={handleInputChange} placeholder="Select a Category" className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"/>
          </div>
          <div className="field">
            <label>Labels</label>
            <MultiSelect name="label_ids" value={formData.label_ids} options={labels} optionLabel="name" optionValue="id" onChange={handleInputChange} placeholder="Select Labels"  className="w-full border border-gray-500 p-2 rounded-md placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-gray-100"/>
          </div>
          <div className="py-2 my-2">
            <label>Upload Images</label>
            <FileUpload  mode="basic"
              accept="image/*"
              maxFileSize={1000000}
              customUpload
              auto
              chooseLabel="Select Image"
              className="custom-file-upload" onSelect={handleFileUpload} />
          </div>
          <Button label={editingId ? "Update" : "Save"} className="w-full bg-[#BA1500] text-center text-white py-3 disabled:opacity-50" onClick={saveBlog} />
        </div>
      </Dialog>
    </div>
  );
};

export default ManageBlog;
