import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/blogs`;

// Fetch all properties
export const fetchBlogs = async () => {
  try {
    const response = await axiosInstance.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};