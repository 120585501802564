import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/events`;

// Fetch all events

export const getEvents = async () =>{
    try {
        const response = await axiosInstance.get(API_URL);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fetch event by id

export const getEventById = async (eventId) =>{
    try {
        const response = await axiosInstance.get(`${API_URL}/${eventId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Create a new event

export const createEvent = async (eventData) =>{
    try {
        const response = await axiosInstance.post(API_URL, eventData);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Update an event

export const updateEvent = async ({eventId, eventData}) =>{
    console.log(eventData,eventId)
    try {
        const response = await axiosInstance.put(`${API_URL}/${eventId}`, eventData);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Delete an event

export const deleteEvent = async (eventId) =>{
    try {
        const response = await axiosInstance.delete(`${API_URL}/${eventId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const bookEvent = async ({userId, eventId}) =>{
    try {
        const response = await axiosInstance.post(`${API_URL}/book`, { eventId,userId });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addEventImage =async ({eventId, imageData}) =>{
    try {
        const formData = new FormData();
        imageData.forEach((file) => formData.append('images', file));
       const response = await axiosInstance.post(`${API_URL}/${eventId}/images`, formData);
       return response.data
    } catch (error) {
        throw error;
    }
}