import { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";

const LocationPicker = ({ setFieldValue,value }) => {
  const [position, setPosition] = useState(value);

  function MapClickHandler() {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setPosition([lat, lng]);
        setFieldValue("coordinates", [lat, lng]);
      },
    });
    return null;
  }

  return (
    <>
      <MapClickHandler />
      {position && (
        <Marker position={position}>
          <Popup>
            Picked Location: {position[0]}, {position[1]}
          </Popup>
        </Marker>
      )}
    </>
  );
};

export default LocationPicker;
