import React,{useEffect} from "react";
import { Upload, Modal, message, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const FileUploadDialog = ({ visible, onClose, selectedEvent, addEventImages, addEventImagesLoading, addEventImagesSuccess,addEventImagesReset }) => {

  useEffect(() => {
    if (addEventImagesSuccess) {
      onClose();
      addEventImagesReset();
    }
  }, [addEventImagesSuccess, onClose, addEventImagesReset]);

  const handleUpload = async ({ file, onError, onProgress, onSuccess }) => {
    let progress = 0;
    const fakeProgress = setInterval(() => {
      progress += 20;
      if (progress < 100) {
        onProgress({ percent: progress });
      } else {
        clearInterval(fakeProgress);
      }
    }, 500);

    try {
      clearInterval(fakeProgress);
      await addEventImages({ eventId: selectedEvent, imageData: [file] });
      onSuccess("File uploaded successfully", file);
      message.success(`${file.name} uploaded successfully!`);
    } catch (error) {
      clearInterval(fakeProgress);
      onError(error);
      message.error(`${file.name} upload failed!`);
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    customRequest: handleUpload,
    disabled: addEventImagesLoading,
  };

  return (
    <Modal
      title="Add Images"
      open={visible}
      width="50vw"
      onCancel={() =>onClose()}
      footer={null}
    >
      {addEventImagesLoading && (
        <div className="flex justify-center mb-4">
          <Spin size="large" />
        </div>
      )}
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Supports single or bulk uploads.</p>
      </Dragger>
    </Modal>
  );
};

export default FileUploadDialog;