import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useProperties } from "../../../hooks/useProperty";
import { Empty } from "antd";

const COLORS = ["#003049", "#669bbc", "#780000", "#c1121f"];

const RealEstatePieChart = () => {
  const { countByStatusData = [] } = useProperties();

  const formattedData = useMemo(() => {
    if (!Array.isArray(countByStatusData)) return [];
    const statusCounts = {};

    countByStatusData.forEach(({ status, count }) => {
      statusCounts[status] = (statusCounts[status] || 0) + count;
    });

    return Object.entries(statusCounts).map(([name, value]) => ({ name, value }));
  }, [countByStatusData]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      {formattedData.length > 0 ?<PieChart>
        <Pie data={formattedData} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label>
          {formattedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart> : (
    <div className="flex items-center justify-center h-full text-gray-500">
      <Empty />
    </div>
  )}
    </ResponsiveContainer>
  );
};

export default RealEstatePieChart;
