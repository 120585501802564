
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="max-w-5xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <header className="bg-[#BA1500] text-white py-6 px-8">
          <h1 className="text-3xl font-bold">Privacy Policy</h1>
          <p className="text-sm mt-2">
            This privacy policy explains how American Realty Network LLC collects, uses, and
            protects your data when you visit our website
          </p>
        </header>

        <main className="p-8 space-y-8">
          <section>
            <p className="text-gray-700 leading-relaxed">
              We reserve the right to change this policy at any time. If you want to stay updated
              with the latest changes, we recommend frequently visiting this page.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">What User Data We Collect</h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Your IP address.</li>
              <li>Your contact information and email address.</li>
              <li>Other information such as interests and preferences.</li>
              <li>Data profile regarding your online behavior on our website.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why We Collect Your Data</h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>To better understand your needs.</li>
              <li>To improve our services and products.</li>
              <li>To send you promotional emails containing relevant information.</li>
              <li>To contact you for surveys and market research.</li>
              <li>To customize our website according to your preferences.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Safeguarding and Securing the Data</h2>
            <p className="text-gray-700 leading-relaxed">
              American Realty Network LLC is committed to protecting your data. We use advanced
              technologies and software to prevent data theft, unauthorized access, and
              disclosure, ensuring your information is secure.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Cookie Policy</h2>
            <p className="text-gray-700 leading-relaxed">
              By allowing our website to use cookies, you agree to us collecting data about your
              online behavior. This helps us customize the website to your needs. The data is
              used for statistical analysis and is then removed from our systems.
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Cookies do not give us access to your computer. They are strictly for monitoring
              useful website metrics. You can disable cookies in your browser settings.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Links to Other Websites</h2>
            <p className="text-gray-700 leading-relaxed">
              Our website may contain links to other websites. American Realty Network LLC is not
              responsible for your data protection on external sites. We recommend reviewing their
              privacy policies.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Restricting the Collection of Your Data
            </h2>
            <p className="text-gray-700 leading-relaxed">
              You can restrict data collection by opting out where applicable (e.g., unchecking
              boxes on forms). If you have shared your data and wish to withdraw consent, contact
              us via email, and we will make the necessary changes.
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              We will never sell, lease, or distribute your information without your permission
              unless required by law.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
