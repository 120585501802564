import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axiosInstance from "../utils/axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const Team = () => {
  const [boardMembers, setBoardMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    axiosInstance
      .get("/api/team")
      .then((response) => setBoardMembers(response.data))
      .catch((error) => console.error("Error fetching board members:", error));
  }, []);

  const openDialog = (member) => {
    setSelectedMember(member);
    setVisible(true);
  };

  return (
    <div className="flex items-center justify-center mx-auto w-full text-red-900">
      <div className="flex flex-col justify-center items-center mx-auto">
        {/* Title */}
        <motion.h1
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="lg:text-3xl text-2xl font-black mb-8 text-center"
        >
          AMERICAN REALTY NETWORK LLC TEAM
        </motion.h1>
        <motion.div
          className="flex mt-4 gap-12 flex-col lg:flex-row max-w-4xl items-center"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { staggerChildren: 0.3 },
            },
          }}
        >
          {boardMembers.map((member) => (
            <motion.div
              key={member.id}
              className="flex flex-col items-center text-center bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow w-2/3"
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
              }}
              whileHover={{ scale: 1.05 }}
              
            >
              {/* Profile Image */}
              <motion.img
                src={member.imageUrl}
                alt={member.fullName}
                className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-md cursor-pointer"
                whileHover={{ scale: 1.1, rotate: 2 }}
                onClick={() => openDialog(member)}
              />

              {/* Member Details */}
              <p className="font-bold mt-4">{member.fullName}</p>
              <p className="text-gray-500">{member.company}</p>
              <p className="text-sm text-red-800 font-semibold mt-2">{member.email}</p>
              <p className="text-sm text-gray-700">{member.phone}</p>

              {/* Shortened Bio */}
             
            </motion.div>
          ))}
        </motion.div>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="lg:w-2/4 w-full leading-relaxed text-red-900 bg-white mt-8 p-4 border-s-4 border-red-900 text-center"
        >
          At About Yarcobr, we are more than just real estate agents—we are trusted advisors committed to helping you find your dream home, sell your property, or make smart investments.
        </motion.p>

        <Dialog
          header={selectedMember ? selectedMember.fullName : ""}
          visible={visible}
          style={{ width: "30vw" }}
          onHide={() => setVisible(false)}
        >
          {selectedMember && (
            <div className="p-4">
              <img
                src={selectedMember.imageUrl}
                alt={selectedMember.fullName}
                className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-md mx-auto"
              />
              <p className="text-lg font-semibold text-center mt-3">{selectedMember.company}</p>
              <p className="text-center text-sm text-gray-500">{selectedMember.email}</p>
              <p className="text-center text-sm text-gray-500">{selectedMember.phone}</p>
              <p className="mt-4 text-gray-800 leading-relaxed">{selectedMember.bio}</p>
            </div>
          )}
          <div className="flex justify-end">
            <Button label="Close" className="p-button-danger" onClick={() => setVisible(false)} />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Team;
