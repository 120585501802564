
import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';

export const DialogMaximize = ({visible,header, onClose,body})=> {
    return (
        <div className="card flex justify-content-center">
            <Dialog header={header} visible={visible} maximizable style={{ width: '50vw' }} onHide={() => {onClose()}}>
                {body}
            </Dialog>
        </div>
    )
}
        