import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Dialog } from "primereact/dialog";
import axiosInstance from "../utils/axios";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";


const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get("/api/blogs")
      .then(response => setBlogs(response.data))
      .catch(error => console.error("Error fetching blogs:", error));
  }, []);

  const openBlog = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="flex items-center justify-center mx-auto w-full text-red-900 lg:my-20 my-10">
      <div className="flex flex-col justify-center items-center mx-auto">
        {/* Title Animation */}
        <motion.h1
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="lg:text-3xl text-2xl font-black mb-8 text-center"
        >
          Latest News
        </motion.h1>

        {/* Blogs Grid */}
        <motion.div
          className="grid mt-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 max-w-6xl"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { staggerChildren: 0.3 },
            },
          }}
        >
          {blogs.map((blog, index) => (
            <motion.article
              key={index}
              className="overflow-hidden rounded-lg border border-gray-100 bg-white shadow-xs"
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
              }}
              whileHover={{ scale: 1.02 }}
            >
              {/* Blog Image */}
              <img
                alt={blog.title}
                src={blog.images?.[0]?.image_url || "https://via.placeholder.com/300"}
                className="h-56 w-full object-cover"
              />

              <div className="p-4 sm:p-6">

{blog.category && (
    <span className="rounded-full bg-purple-100 px-2.5 py-0.5 text-xs m-2 whitespace-nowrap text-purple-600">
    {blog.category.name}
   </span>
  )}
  
  {blog.labels.length > 0 && (
    <span className="rounded-full bg-purple-100 px-2.5 py-0.5 text-xs m-2 whitespace-nowrap text-purple-600">
 {blog.labels.map(label => label.label.name).join(", ")}
   </span>
  )}
  <span className="rounded-full bg-purple-100 px-2.5 py-0.5 text-xs m-2 whitespace-nowrap text-purple-600">{new Date(blog.createdAt).toLocaleDateString()} </span>
  <a href="#"  onClick={() => openBlog(blog.id)} className="mt-2 block hover:underline">
                  <h3 className="text-lg font-medium text-gray-900">{blog.title}</h3>
                </a>
                <div className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.content) }} />

                
                <button
                  onClick={() => openBlog(blog.id)}
                  className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-600"
                >
                  Read More
                  <span aria-hidden="true" className="block transition-all group-hover:ms-0.5">
                    &rarr;
                  </span>
                </button>
              </div>
            </motion.article>
          ))}
        </motion.div>

        {/* Blog Details Modal */}
        {/* <Dialog header="" visible={visible} style={{ width: "50vw" }} onHide={() => setVisible(false)}>
          {selectedBlog && (
            <div>
              <img
                alt={selectedBlog.title}
                src={selectedBlog.images?.[0]?.image_url || "https://via.placeholder.com/600"}
                className="h-64 w-full object-cover rounded-md mb-4"
              />
              <h2 className="text-2xl font-bold text-gray-900">{selectedBlog.title}</h2>
              <p className="mt-2 text-gray-700">{selectedBlog.content}</p>
              <p className="mt-2 text-xs text-gray-500">Published on: {new Date(selectedBlog.createdAt).toLocaleDateString()}</p>
              {selectedBlog.category && (
                <p className="mt-2 text-sm font-semibold text-gray-700">Category: {selectedBlog.category.name}</p>
              )}
              {selectedBlog.labels.length > 0 && (
                <p className="mt-2 text-sm text-gray-600">Labels: {selectedBlog.labels.map(label => label.label.name).join(", ")}</p>
              )}
            </div>
          )}
        </Dialog> */}
      </div>
    </div>
  );
};

export default BlogList;
