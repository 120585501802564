import React, { useState } from "react";

const PdfViewer = ({ pdfUrl }) => {
  const [loading, setLoading] = useState(true);
  const googleDocsViewer = `https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`;

  return (
    <div style={{ position: "relative", width: "100%", height: "600px" }}>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          <span className="text-lg font-semibold text-gray-600">Loading PDF...</span>
        </div>
      )}
      <iframe
        src={googleDocsViewer}
        width="100%"
        height="600px"
        style={{ border: "none" }}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default PdfViewer;
