import React, { useMemo } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useProperties } from "../../../hooks/useProperty";
import { Empty } from "antd";

const SalesTrendChart = () => {
  const { countByStatusData } = useProperties();

  const formattedData = useMemo(() => {
    const groupedData = {};
    countByStatusData?.forEach(({ month, status, count }) => {
      if (!groupedData[month]) {
        groupedData[month] = { month };
      }
      groupedData[month][status] = count;
    });

    return Object.values(groupedData);
  }, [countByStatusData]);

  const statusKeys = useMemo(() => {
    const keys = new Set();
    countByStatusData?.forEach(({ status }) => keys.add(status));
    return Array.from(keys);
  }, [countByStatusData]);

  return (
<ResponsiveContainer width="100%" height={window.innerWidth < 768 ? 200 : 300}>
  {formattedData.length > 0 ? (
    <LineChart data={formattedData}>
      <XAxis dataKey="month" interval="preserveStartEnd" />
      <YAxis />
      <Tooltip />
      <Legend wrapperStyle={{ display: window.innerWidth < 768 ? "none" : "block" }} />
      {statusKeys.map((key, index) => (
        <Line key={key} type="monotone" dataKey={key} stroke={index % 2 ? "#669bbc" : "#003049"} strokeWidth={2} />
      ))}
    </LineChart>
  ) : (
    <div className="flex items-center justify-center h-full text-gray-500">
      <Empty />
    </div>
  )}
</ResponsiveContainer>


  );
};

export default SalesTrendChart;
