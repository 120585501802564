import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchBlogs } from "../api/blogApi";
import toast from "react-hot-toast";

export const useBlogs = () => {
    const queryClient = useQueryClient();

    // Fetch all blogs
    const { data: blogs, error, isLoading } = useQuery({
        queryKey: ["blogs"],
        queryFn: fetchBlogs,
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to fetch blogs ��");
        }
    });

    return { blogs, isLoading, error };
}