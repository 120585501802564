import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useProperties } from "../hooks/useProperty";
import { useEvents } from "../hooks/useEvent";
import { useBlogs } from "../hooks/useBlog";

const ProtectedRoute = ({ allowedRoles = [] }) => {
  const { isAuthenticated, role, isLoading } = useAuth();
    const { isLoading:propertiesLoading } = useProperties();
    const {  isLoading: eventsLoading } = useEvents();
    const { isLoading: blogsLoading } = useBlogs();
  // Ensure role is always a valid string
  const userRole = role || ""; 
  if(isLoading && propertiesLoading && eventsLoading && blogsLoading){
    return <div className="flex h-screen justify-center items-center"><i className="pi pi-spin pi-spinner text-red-700" style={{ fontSize: '2rem' }}></i></div>
  }
  return !isAuthenticated && !allowedRoles.includes(userRole) ? <Navigate to="/" /> : <Outlet />;
};

export default ProtectedRoute;
