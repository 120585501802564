import React from "react";
import { Card } from "../components/admin/card";
import SalesTrendChart from '../components/admin/charts/lineChart';
import RealEstatePieChart from '../components/admin/charts/pieChart';
import RealEstateMap from '../components/admin/AdminMap/RealEstateMap';
import RealEstateRecent from '../components/admin/Tables/recentTable';
import { useProperties } from "../hooks/useProperty";
import { useEvents } from "../hooks/useEvent";
import { useBlogs } from "../hooks/useBlog";
export const AgentDashboard = () => {
  const { properties, isLoading } = useProperties();
  const { events, isLoading: eventsLoading } = useEvents();
  const { blogs, isLoading: blogsLoading } = useBlogs();

  if (isLoading && eventsLoading && blogsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="py-5 px-4">
      <div>
        <h1 className="text-[24px] text-[#003049] font-semibold">Dashboard</h1>
        <p className="text-gray-400">Welcome to Admin Panel</p>
      </div>
      <div className=" mt-10 grid lg:grid-cols-4 gap-4">
        <Card
          title="Total Properties"
          value={`${properties?.length}`}
          icon="pi pi-home"
        />
        <Card
          title="Total Events"
          value={`${events?.length}`}
          icon="pi pi-calendar"
        />
        <Card
          title="Total Blog Posts"
          value={`${blogs?.length}`}
          icon="pi pi-flag"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div className="mt-10 lg:col-span-2 bg-white py-4 rounded-sm">
        <h2 className="text-[18px] text-[#003049] font-semibold pl-9 pb-2">Sales Trends</h2>
        <SalesTrendChart />
      </div>

      <div className="mt-10 bg-white py-4 rounded-sm">
        <h2 className="text-[18px] text-[#003049] font-semibold pb-2 pl-9">Real Estate Sales</h2>
        <RealEstatePieChart />
      </div>

      <div className="mt-10 bg-white py-4 px-4 rounded-sm lg:col-span-2">
        <RealEstateMap />
      </div>

      <div className="mt-10 bg-white py-4 px-4 rounded-sm">
        <h2 className="text-[18px] text-[#003049] font-semibold pb-2 pl-9">Recent Sales</h2>
        <RealEstateRecent />
      </div>
      </div>

    </div>
  );
};
