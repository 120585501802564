import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getEvents, createEvent, updateEvent, bookEvent, deleteEvent,addEventImage } from "../api/eventApi";

export const useEvents = () => {
    const queryClient = useQueryClient();

    // Fetch all events
    const { data: events, error, isLoading } = useQuery({
        queryKey: ["events"],
        queryFn: getEvents,
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to fetch events ❌");
        },
    });

    // Create a new event
    const createEventMutation = useMutation({
        mutationFn: createEvent,
        onSuccess: (data) => {
            toast.success(data.message || "Event created successfully! ✅");
            queryClient.invalidateQueries(["events"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to create event ❌");
        },
    });

    // Update an event
    const updateEventMutation = useMutation({
        mutationFn: updateEvent,
        onSuccess: (data) => {
            toast.success(data.message || "Event updated successfully! ✅");
            queryClient.invalidateQueries(["events"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to update event ❌");
        },
    });

    // Book an event
    const bookEventMutation = useMutation({
        mutationFn: bookEvent,
        onSuccess: (data) => {
            toast.success(data.message || "Event booked successfully! ✅");
            queryClient.invalidateQueries(["events"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to book event ❌");
        },
    });

    // Delete an event
    const deleteEventMutation = useMutation({
        mutationFn: deleteEvent,
        onSuccess: (data) => {
            toast.success(data.message || "Event deleted successfully! ✅");
            queryClient.invalidateQueries(["events"]);
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to delete event ❌");
        },
    });

    const addEventImages = useMutation({
        mutationFn: addEventImage,
        onSuccess: (data) => {
            toast.success(data.message || "Event images added successfully! ✅");
            queryClient.invalidateQueries(["events"]);
            // addEventImages.reset()
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to add event images ��");
            addEventImages.reset()
        },
    })

    return {
        events,
        isLoading,
        error,
        createEvent: createEventMutation.mutate,
        updateEvent: updateEventMutation.mutate,
        bookEvent: bookEventMutation.mutate,
        deleteEvent: deleteEventMutation.mutate,
        addEventImages: addEventImages.mutate,
        createEventLoading: createEventMutation.isPending,
        updateEventLoading: updateEventMutation.isPending,
        bookEventLoading: bookEventMutation.isPending,
        deleteEventLoading: deleteEventMutation.isPending,
        addEventImagesLoading: addEventImages.isPending,
        createEventSuccess : createEventMutation.isSuccess,
        addEventImagesSuccess: addEventImages.isSuccess,
        updateEventSuccess: updateEventMutation.isSuccess,
        deleteEventSuccess: deleteEventMutation.isSuccess,
        addEventImagesReset: addEventImages.reset,
    };
};
