import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Property from "./property";
import Logo from "./assets/logo.png";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Users from "../pages/users";
import Team from "../pages/teammanager";
import { EventManagement } from "../pages/eventManagement";
import ManageBlog from "../pages/ManageBlog";
import { Dashboard } from "../pages/dashboard";
import CommunityManagePage from "../pages/communityManager";
import { FloorPlanManagement } from "../pages/FloorPlanManagement";
import { DocManagement } from "../pages/docManagement";
import ManageBoardMembers from "../pages/bordManagement";

const Admin = () => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    logout();
    navigate("/");
  };
  const renderContent = () => {
    switch (activeTab) {
      case "Dashboard":
        return <Dashboard />;
      case "CW Listings":
        return <Property />;
      case "CW Admins":
        return <Users />;
      case "CW Agents":
        return <Team />;
      case "Calendar":
        return <EventManagement />;
      case "News":
        return <ManageBlog />;
      case "Floor Plans":
        return <FloorPlanManagement />;
      case "HOA Documents":
        return <DocManagement />;
      case "Community Info":
        return <CommunityManagePage />;
      case "Board Members":
        return <ManageBoardMembers />;
      default:
        return <div>Select a tab</div>;
    }
  };

  // Function to handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen font-Poppins lg:flex-row">
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      <aside
        className={`
          fixed 
          top-0 
          left-0 
          h-full 
          bg-red-600 
          text-white 
          shadow-md 
          w-52
          transform 
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} 
          transition-transform duration-300 
          z-30 
          lg:static 
          lg:translate-x-0 
          lg:w-52
        `}
      >
        <div className="flex items-center justify-between py-6 px-4">
          <div className="flex items-center">
            <img src={Logo} className="w-12 h-12 object-contain" alt="Logo" />
            <span className="text-base font-semibold hidden lg:inline-block">
              AboutYARCOBR
            </span>
          </div>
          <button
            className="lg:hidden text-white focus:outline-none"
            onClick={toggleSidebar}
            aria-label="Close Sidebar"
          >
            <Icon icon="mdi:close" className="text-2xl" />
          </button>
        </div>

        <nav className="flex-grow space-y-2 px-2">
          {[
            { name: "Dashboard", icon: "material-symbols:dashboard-rounded" },
            { name: "CW Listings", icon: "mdi:home" },
            { name: "CW Admins", icon: "mdi:account-group" },
            { name: "CW Agents", icon: "mdi:account-group" },
            { name: "Board Members", icon: "mdi:account-group" },
            { name: "Calendar", icon: "mdi:events" },
            { name: "News", icon: "mdi:blog" },
            { name: "Floor Plans", icon: "mdi:floor-plan" },
            { name: "Community Info", icon: "mdi:account-group" },
            { name: "HOA Documents", icon: "mdi:document" },
          ].map(({ name, icon }) => (
            <button
              key={name}
              onClick={() => {
                setActiveTab(name);
                setIsSidebarOpen(false);
              }}
              className={`w-full flex items-center gap-4 px-4 py-3 rounded-lg text-sm font-medium transition ${
                activeTab === name
                  ? "bg-red-500 text-white"
                  : "hover:bg-red-700 hover:text-white"
              }`}
              aria-current={activeTab === name ? "page" : undefined}
            >
              <Icon icon={icon} className="text-xl" />
              <span className="ml-2">{name}</span>{" "}
            </button>
          ))}
          {/* Logout Button */}
          <button
            onClick={handleLogOut}
            className="w-full mx-auto space-y-2 flex items-center gap-4 px-4 py-3 text-sm rounded-md font-medium text-white hover:bg-red-700 hover:text-white transition"
          >
            <Icon icon="mdi:logout" className="text-xl" />
            <span className="ml-2">Logout</span>
          </button>
        </nav>
      </aside>

      {/* Top Navigation Bar for Mobile */}
      <header className="flex items-center justify-between bg-red-600 text-white p-4 lg:hidden">
        <button
          onClick={toggleSidebar}
          className="focus:outline-none"
          aria-label="Open Sidebar"
        >
          <Icon icon="mdi:menu" className="text-2xl" />
        </button>
        <div className="flex items-center">
          <img src={Logo} className="w-8 h-8 object-contain" alt="Logo" />
          <span className="ml-2 text-lg font-semibold">AboutYARCOBR</span>
        </div>
        {/* Placeholder for alignment */}
        <div className="w-8 h-8"></div>
      </header>

      {/* Main Content */}
      <main className="flex-1 bg-gray-100 overflow-auto">
        <section className="rounded-lg">{renderContent()}</section>
      </main>
    </div>
  );
};

export default Admin;
