import axiosInstance from "../utils/axios";
import { URL } from "../utils/axios";

const API_URL = `${URL}/api/contacts`;

// Fetch all contacts
export const fetchContacts = async () => {
  try {
    const response = await axiosInstance.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create a new contact
export const createContact = async (contactData) => {
  try {
    const response = await axiosInstance.post(API_URL, contactData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a contact
export const updateContact = async ({ contactId, contactData }) => {
  try {
    const response = await axiosInstance.put(`${API_URL}/${contactId}`, contactData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a contact
export const deleteContact = async (contactId) => {
  try {
   const response = await axiosInstance.delete(`${API_URL}/${contactId}`);
   return response.data;
  } catch (error) {
    throw error;
  }
};

// send email 
export const sendEmail = async (emailData) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/send-email`, emailData);
    return response.data;
  } catch (error) {
    throw error;
  }
};