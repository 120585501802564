import React, { useState, useEffect } from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const FileUploader = ({ onFileChange, createDocsSuccess,multiple = false }) => {
  const [fileList, setFileList] = useState([]);

  const handleBeforeUpload = (file) => {
    setFileList((prevList) => [...prevList, file]);
    onFileChange(file);
    return false;
  };

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter(item => item.uid !== file.uid));
    onFileChange(null);
  };

  useEffect(() => {
    setFileList([]);
    onFileChange(null);
  }, [createDocsSuccess]);

  return (
    <Upload
      fileList={fileList}
      accept="application/pdf"
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
      multiple={multiple}
    >
      <Button
        className="group border border-gray-400 hover:!border-red-600 focus:!border-red-600 
             text-gray-700 hover:!text-red-600 focus:!text-red-600"
      >
        <UploadOutlined className="group-hover:!text-red-600 group-focus:!text-red-600" />
        Select File
      </Button>
    </Upload>
  );
};


export default FileUploader;
