import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import "./index.css";
import "leaflet/dist/leaflet.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import App from "./App";
import { IntlProvider } from "react-intl";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import { PrimeReactProvider } from "primereact/api";
import { Toaster } from "react-hot-toast";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <PrimeReactProvider>
      <IntlProvider locale="en">
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <App />
      </IntlProvider>
    </PrimeReactProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
