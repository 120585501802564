import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useProperties } from "../../../hooks/useProperty";

const STATUS_COLORS= {
  Sale: "#003049",
  Sold: "#669bbc",
  Rent: "#780000",
  Rented: "#c1121f",
};

const RealEstateMap = () => {
  const { properties = [] } = useProperties();

  const formattedProperties = properties
    .map((property) => {
      if (!property.coordinates) return null;
      const [lat, lng] = property.coordinates.split(",").map(Number);
      if (isNaN(lat) || isNaN(lng)) return null;

      return {
        id: property.id,
        type: property.status,
        lat,
        lng,
        color: STATUS_COLORS[property.status] || "#003049",
        details: `${property.bedrooms} Beds | ${property.bathrooms} Baths | ${property.price_or_rent} USD`,
      };
    })
    .filter(Boolean);

  return (
    <div>
      <MapContainer center={[20, 0]} zoom={2} style={{ height: "500px", width: "100%" }}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
        />
        {formattedProperties.map((property) => (
          <CircleMarker
            key={property.id}
            center={[property.lat, property.lng]}
            radius={10}
            fillColor={property.color}
            color="#fff"
            weight={2}
            fillOpacity={0.8}
          >
            <Popup>
              <strong>{property.type} Property</strong>
              <br />
              {property.details}
            </Popup>
          </CircleMarker>
        ))}
      </MapContainer>
    </div>
  );
};

export default RealEstateMap;
