import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import axiosInstance from "../utils/axios";

const PropertyModel = ({ property }) => {
  const [propertyImages, setPropertyImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate(); // Used for navigation

  useEffect(() => {
    if (!property?.id) return;

    const fetchImages = async () => {
      try {
        const response = await axiosInstance.get(`/api/images/${property.id}`);
        setPropertyImages(response.data.length > 0 ? response.data : [{ image_url: "https://via.placeholder.com/300" }]);
      } catch (error) {
        console.error("Error fetching property images:", error);
        setPropertyImages([{ image_url: "https://via.placeholder.com/300" }]); // Fallback image
      }
    };

    fetchImages();
  }, [property]);
  

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % propertyImages.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? propertyImages.length - 1 : prevIndex - 1));
  };

  return (
    <div className="w-full">
      <a href="#" className="block rounded-lg p-4 shadow-xs shadow-indigo-100 w-72">
        {/* Property Image */}
        <div className="relative h-56 w-full bg-gray-200 rounded-md">
        {propertyImages?.length > 0 ? (
            <>
          <img
            alt="Property"
            src={propertyImages[currentIndex]?.image_url}
            className="h-full w-full rounded-md object-cover"
          />
          {/* Image Navigation Buttons */}
          <div className="absolute top-1/2 left-0 right-0 flex justify-between px-2">
            <button
              onClick={prevImage}
              className="px-2 py-1 bg-gray-700 text-white rounded-lg hover:bg-gray-800"
            >
              <Icon icon="ep:arrow-left-bold" width="16" height="16" />
            </button>
            <button
              onClick={nextImage}
              className="px-2 py-1 bg-gray-700 text-white rounded-lg hover:bg-gray-800"
            >
              <Icon icon="weui:arrow-filled" width="16" height="16" />
            </button>
          </div>
          </>
                  
              ) : (
                <p className="text-center"> <span className="loader animate-spin border-t-2 border-white border-solid rounded-full h-5 w-5"></span> Loading images...</p>
              )}
        </div>

        {/* Property Details */}
        <div className="mt-2">
          <dl>
            <div>
              <dt className="sr-only">Price</dt>
              <dd className="text-sm text-red-600">${property.price_or_rent}</dd>
            </div>

            <div>
              <dt className="sr-only">Address</dt>
              <dd className="font-medium">{property.city}, {property.state}</dd>
            </div>
          </dl>

          {/* Property Features */}
          <div className="mt-6 flex items-center gap-8 text-xs">

            <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
              <svg className="size-4 text-Colorb" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
              </svg>
              <div className="mt-1.5 sm:mt-0">
                <p className="text-gray-500">Bathroom</p>
                <p className="font-medium">{property.bathrooms} rooms</p>
              </div>
            </div>

            <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
              <svg className="size-4 text-Colorb" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"/>
              </svg>
              <div className="mt-1.5 sm:mt-0">
                <p className="text-gray-500">Bedroom</p>
                <p className="font-medium">{property.bedrooms} rooms</p>
              </div>
            </div>
          </div>

          {/* View More Details Button */}
          <button
            onClick={() => navigate(`/propertyDetails/${property.id}`)}
            className="mt-4 w-full bg-Colorb text-white px-3 py-2 rounded-lg hover:bg-Colorb transition"
          >
            View More Details
          </button>
        </div>
      </a>
    </div>
  );
};

export default PropertyModel;
