import {jwtDecode} from "jwt-decode";
import toast from 'react-hot-toast';

export const getUser = (token) => {
  if (!token) return null;

  try {
    const decodedToken =  jwtDecode(token);
    return {decodedToken}
  } catch (error) {
    toast.error(error);
  }
};