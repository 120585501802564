import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchRoles, createRole, updateRole, deleteRole, changeUserRole } from "../api/rolesApi";
import toast from "react-hot-toast";

export const useRoles = () => {
    const queryClient = useQueryClient();

    // Fetch roles
    const { data: roles, error, isLoading } = useQuery({
        queryKey: ["roles"],
        queryFn: fetchRoles,
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to fetch roles ❌");
        },
    });

    // Create a new role
    const createRoleMutation = useMutation({
        mutationFn: createRole,
        onSuccess: (data) => {
            toast.success(data?.message || "Role created successfully! ✅");
            queryClient.invalidateQueries(["roles"]); // Refresh role data
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to create role ❌");
        },
    });

    // Update a role
    const updateRoleMutation = useMutation({
        mutationFn: updateRole,
        onSuccess: (data) => {
            toast.success(data?.message || "Role updated successfully! ✅");
            queryClient.invalidateQueries(["roles"]); // Refresh role data
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to update role ❌");
        },
    });

    // Change user role
    const changeUserRoleMutation = useMutation({
        mutationFn: changeUserRole,
        onSuccess: (data) => {
            toast.success(data?.message || "User role updated successfully! ✅");
            queryClient.invalidateQueries(["roles"]); // Refresh role data
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to update role ❌");
        },
    });

    // Delete a role
    const deleteRoleMutation = useMutation({
        mutationFn: deleteRole,
        onSuccess: (data) => {
            toast.success(data?.message || "Role deleted successfully! ✅");
            queryClient.invalidateQueries(["roles"]); // Refresh role data
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to delete role ❌");
        },
    });

    return {
        roles,
        isLoading,
        error,
        createRole: createRoleMutation.mutate,
        updateRole: updateRoleMutation.mutate,
        deleteRole: deleteRoleMutation.mutate,
        changeUserRole: changeUserRoleMutation.mutate,
        createRoleLoading: createRoleMutation.isPending,
        updateRoleLoading: updateRoleMutation.isPending,
        deleteRoleLoading: deleteRoleMutation.isPending,
        changeUserRoleLoading: changeUserRoleMutation.isPending,
    };
};
